import React, { KeyboardEventHandler } from 'react';

type SearchProps = {
  container?: HTMLElement | null;
  value?: string;
  onChange?: (searchTerm: string) => void;
  onKeyDown?: (e: any) => void;
  onSelect?: (e: any) => void;
};

// const changeUnderlineColor = () => {
//   const container:HTMLElement | null = document.getElementById('search-container');
//   container?.style.color = 'red'
// };
// onKeyPress={changeUnderlineColor}

const SearchBar: React.FC<SearchProps> = ({
  value,
  onKeyDown,
  onChange,
  // onSelect,
}) => {
  return (
    <div id='search-container'>
      <input
        value={value}
        onKeyDown={onKeyDown}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          onChange && onChange(e.target.value)
        }
        // onSelect={onSelect}
      />
    </div>
  );
};

export default SearchBar;
