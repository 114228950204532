import { ActorSubclass, AnonymousIdentity } from '@dfinity/agent';

import { _SERVICE as UserService } from '../../../.dfx/local/canisters/FastBlocks_Users/FastBlocks_Users.did';
import {
  canisterId as userCanisterId,
  createActor as createUserActor,
} from '../../../.dfx/local/canisters/FastBlocks_Users';

import { _SERVICE as PostService } from '../../../.dfx/local/canisters/FastBlocks_Posts/FastBlocks_Posts.did';
import {
  canisterId as postCanisterId,
  createActor as createPostActor,
} from '../../../.dfx/local/canisters/FastBlocks_Posts';

import { _SERVICE as PostIndexService } from '../../../.dfx/local/canisters/FastBlocks_PostIndex/FastBlocks_PostIndex.did';
import {
  canisterId as postIndexCanisterId,
  createActor as createPostIndexActor,
} from '../../../.dfx/local/canisters/FastBlocks_PostIndex';

import { _SERVICE as StorageService } from '../../../.dfx/local/canisters/FastBlocks_Storage/FastBlocks_Storage.did';
import {
  canisterId as storageCanisterId,
  createActor as createStorageActor,
} from '../../../.dfx/local/canisters/FastBlocks_Storage';

import { _SERVICE as EmailOptInService } from '../../../.dfx/local/canisters/FastBlocks_EmailOptIn/FastBlocks_EmailOptIn.did';
import {
  canisterId as emailOptInCanisterId,
  createActor as createEmailOptInActor,
} from '../../../.dfx/local/canisters/FastBlocks_EmailOptIn';

import { useAuthStore } from '../store';

// export types generated by dfx
export type {
  User,
  Followers,
} from '../../../.dfx/local/canisters/FastBlocks_Users/FastBlocks_Users.did';
export type {
  UserPostCounts,
  PostSaveModel,
  PostTag,
  PostTagModel,
  TagModel,
} from '../../../.dfx/local/canisters/FastBlocks_Posts/FastBlocks_Posts.did';
export type { Content } from '../../../.dfx/local/canisters/FastBlocks_Storage/FastBlocks_Storage.did';

// export actor references generated by dfx
export async function getUserActor(): Promise<ActorSubclass<UserService>> {
  var identity =
    (await useAuthStore?.getState().getIdentity()) || new AnonymousIdentity();
  return createUserActor(userCanisterId as string, {
    agentOptions: { identity },
  });
}

export async function getPostActor(): Promise<ActorSubclass<PostService>> {
  var identity =
    (await useAuthStore?.getState().getIdentity()) || new AnonymousIdentity();
  return createPostActor(postCanisterId as string, {
    agentOptions: { identity },
  });
}

export async function getPostIndexActor(): Promise<
  ActorSubclass<PostIndexService>
> {
  var identity =
    (await useAuthStore?.getState().getIdentity()) || new AnonymousIdentity();
  return createPostIndexActor(postIndexCanisterId as string, {
    agentOptions: { identity },
  });
}

export async function getStorageActor(): Promise<
  ActorSubclass<StorageService>
> {
  var identity =
    (await useAuthStore?.getState().getIdentity()) || new AnonymousIdentity();
  return createStorageActor(storageCanisterId as string, {
    agentOptions: { identity },
  });
}

export async function getEmailOptInActor(): Promise<
  ActorSubclass<EmailOptInService>
> {
  var identity =
    (await useAuthStore?.getState().getIdentity()) || new AnonymousIdentity();
  return createEmailOptInActor(emailOptInCanisterId as string, {
    agentOptions: { identity },
  });
}
