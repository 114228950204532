import React from 'react';

type ButtonProps = {
  type?: String;
  styleType?: String;
  icon?: any;
  style?: Object;
  onClick?: (event: any) => void;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = (props): JSX.Element => {
  const { styleType, icon, children, type, disabled, style, onClick } = props;

  return (
    <button
      className={'button-attributes-' + styleType}
      style={style}
      onClick={onClick}
      disabled={disabled ? true : false}
    >
      {icon ? icon: ''}
      {children}
    </button>
  );
};

export default Button;
