import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import EmailOptIn from '../../components/email-opt-in/email-opt-in';
import ReadArticleSidebar from '../../UI/read-article-sidebar/read-article-sidebar';
import CopyArticle from '../../UI/copy-article/copy-article';
import ReportArticle from '../../UI/report-article/report-article';
import { useAuthStore, usePostStore, useUserStore } from '../../store';
import Loader from '../../UI/loader/Loader';
import { formatDate } from '../../shared/utils';
import { colors, images } from '../../shared/constants';
import 'react-quill/dist/quill.snow.css';
import FollowAuthor from '../../components/follow-author/follow-author';
import Button from '../../UI/Button/Button';
import { icons } from '../../shared/constants';
import ClapButton from '../../UI/clap-button/clap-button';

const ReadArticle = () => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [copyArticle, setCopyArticle] = useState(false);
  const [meatBall, setMeatBall] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const [loading, setLoading] = useState(false);

  const [isToggled, setIsToggled] = useState(false);

  const navigate = useNavigate();
  const { handle, id } = useParams();

  const {
    getPost,
    clearPost,
    setSearchText,
    clapPost,
    clearSearchBar,
    isTagScreen,
    post,
    author,
    loadingError,
  } = usePostStore((state) => ({
    getPost: state.getPost,
    clearPost: state.clearPost,
    setSearchText: state.setSearchText,
    post: state.post,
    author: state.author,
    loadingError: state.getPostError,
    clapPost: state.clapPost,
    clearSearchBar: state.clearSearchBar,
    isTagScreen: state.isTagScreen,
  }));

  const user = useUserStore((state) => state.user);
  const { getUser } = useUserStore((state) => ({ getUser: state.getUser }));

  const load = () => {
    if (handle && id) {
      setLoading(true);
      getPost(handle, id);
    }
  };

  const getTitleFromUrl = (url: string) => {
    const segments = new URL(url).pathname.split('/');
    const title = segments.pop() || segments.pop() || '';
    return title;
  };

  const searchTag = (tag: string) => {
    setSearchText('#' + tag);
    clearSearchBar(false);
    navigate('/', { replace: true });
  };

  useEffect(() => {
    clearPost();
    window.scrollTo(0, 0);
    load();
    return () => {
      clearPost();
    };
  }, []);

  useEffect(() => {
    if (post || loadingError) {
      // redirect user if the post title does not match current title
      if (post) {
        if (
          getTitleFromUrl(window.location.origin + post.url) !==
          getTitleFromUrl(window.location.href)
        ) {
          window.location.href = post.url;
        }
      }

      setLoading(false);
    }
  }, [post, loadingError]);

  useEffect(
    (window.onresize = window.onload =
      () => {
        setScreenWidth(window.innerWidth);
      }),
    [screenWidth]
  );

  const isSidebarToggled = (data: any) => {
    setIsToggled(!isToggled);
  };

  const [postclaps, setPostclaps] = useState('0');
  const [tokenAnimate, setTokenAnimate] = useState(false);
  const [buttoncount, setButtonCount] = useState(0);
  const [mousedown, setMouseDown] = useState(false);
  const [clapDisabled, setClapDisabled] = useState(false);

  //disabled for null user and on authored posts
  useEffect(() => {
    if (!user) {
      setClapDisabled(true);
    } else {
      setClapDisabled(
        user.handle == post?.handle || user?.nuaTokens - buttoncount <= 0
      );
    }
  }, [user, post]);

  useEffect(() => {
    if (user)
      setClapDisabled(
        user?.nuaTokens - buttoncount <= 0 || user.handle == post?.handle
      );
  }, [postclaps, user]);

  function nineClaps() {
    for (let i = 0; i < 9; i++) {
      clapPost(post?.postId || '');
    }
  }

  useEffect(() => {
    if (mousedown && user) {
      //single clap
      if (user?.nuaTokens - buttoncount >= 1) {
        setButtonCount((prevCounter) => prevCounter + 1);
        clapPost(post?.postId || '');
        //ovation effect, user must hold button for 2 seconds for 10 claps total
        if (screenWidth > 760 && user?.nuaTokens - buttoncount > 9) {
          const interval = setInterval(() => {
            nineClaps();
            setButtonCount((prevCounter) => prevCounter + 9);
            setMouseDown(false);
            console.log('ovation');
          }, 2000);
          return () => clearInterval(interval);
        }
      }
    }
  }, [mousedown]);

  useEffect(() => {
    setTokenAnimate(true);
    setTimeout(() => {
      setTokenAnimate(false);
    }, 1000);
  }, [buttoncount]);

  useEffect(() => {
    if (post) {
      setPostclaps(post?.claps);
    }
  });

  return (
    <div className='read-article-wrapper'>
      <Helmet>
        <link rel='canonical' href={window.location.href} />

        {/* HTML Meta Tags */}
        <title>FastBlocks</title>
        <meta name='description' content={post?.subtitle} />
        <meta name='author' content={post?.handle} />
        <meta name='title' content={post?.title} />

        {/* Google / Search Engine Tags */}
        <meta itemProp='name' content={post?.title} />
        <meta itemProp='description' content={post?.subtitle} />
        <meta
          itemProp='image'
          content={
            post?.headerImage || window.location.host + images.NUANCE_LOGO
          }
        />

        {/* Facebook Meta Tags */}
        <meta
          property='og:url'
          content={'fastblocks.com' + window.location.pathname}
        />
        <meta property='og:type' content='article' />
        <meta property='og:title' content={post?.title} />
        <meta property='og:description' content={post?.subtitle} />
        <meta
          property='og:image'
          content={
            post?.headerImage || window.location.host + images.NUANCE_LOGO
          }
        />

        {/* Twitter Meta Tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={post?.title} />
        <meta name='twitter:description' content={post?.subtitle} />
        <meta
          name='twitter:image'
          content={
            post?.headerImage || window.location.host + images.NUANCE_LOGO
          }
        />
        <meta name='creator' content='FastBlocks' />

        <link
          rel='stylesheet'
          href='/assets/js/highlight/styles/github-dark-dimmed.min.css'
        ></link>
      </Helmet>

      <Header
        loggedIn={isLoggedIn}
        isArticlePage={false}
        isReadArticlePage={true}
        ScreenWidth={screenWidth}
        tokens={user && user?.nuaTokens - buttoncount}
        loading={tokenAnimate}
      />

      <div className='page'>
        <div
          className='left'
          style={
            screenWidth <= 768 && isToggled
              ? { width: 'max-content' }
              : screenWidth <= 768 && !isToggled
              ? { width: '20px' }
              : { width: '30%' }
          }
        >
          <p className='date'>{formatDate(post?.created) || ' -- '}</p>
          <div className='left-content'>
            <div className='menus'>
              {post?.url && (
                <>
                  <CopyArticle
                    url={post.url}
                    shown={copyArticle}
                    setShown={setCopyArticle}
                  />
                  {/* ReportArticle also takes in parameters for edit button since they share a drop down menu*/}
                  <ReportArticle
                    id={id || ''}
                    handle={author?.handle || ''}
                    url={post?.url}
                    shown={meatBall}
                    setShown={setMeatBall}
                  />
                </>
              )}
            </div>
            <div className='horizontal-divider'></div>
            {post && author && (
              <div className='author'>
                <img src={author.avatar || images.DEFAULT_AVATAR} alt=''></img>
                <Link to={`/${author.handle}`} className='handle'>
                  @{author.handle}
                </Link>
              </div>
            )}
            <div className='horizontal-divider'></div>
          </div>

          <div className='left-menu'>
            <ReadArticleSidebar
              isSidebarToggle={isSidebarToggled}
              id={id}
              url={post?.url || ''}
              handle={post?.handle}
              avatar={author?.avatar}
            />
          </div>
          {
            <div className='side-bar-clap-button'>
              <div
                className={
                  tokenAnimate && buttoncount !== 0
                    ? 'clap-count-container'
                    : 'hide'
                }
              >
                +{buttoncount}
              </div>
              <ClapButton
                styleType='clap-button'
                disabled={clapDisabled}
                type='button'
                style={{ width: '96px' }}
                onMouseDown={() => {
                  setMouseDown(true);
                }}
                onMouseUp={() => {
                  setMouseDown(false);
                }}
              >
                {parseInt(postclaps) + buttoncount}
              </ClapButton>
            </div>
          }
          <div
            style={{
              margin: '0 40px',
              width: '95%',
            }}
          >
            {screenWidth != 0 && screenWidth > 768 && <EmailOptIn></EmailOptIn>}
          </div>
        </div>

        <div className='right'>
          {loading && (
            <div style={{ marginTop: '-50px' }}>
              <Loader />
            </div>
          )}

          {!loading && post && author && (
            <div className='content'>
              <h1>{post.title}</h1>
              <p className='last-modified'>
                last modified: {formatDate(post.modified) || ' -- '}
              </p>
              <h2>{post.subtitle}</h2>
              <img
                className='header-image'
                src={post.headerImage || images.NUANCE_LOGO}
              />
              <div className='text'>{parse(post.content)}</div>

              <div className='tag-and-button'>
                {
                  <div className='clap-footer'>
                    <div
                      className={
                        tokenAnimate && buttoncount !== 0
                          ? 'clap-count-container-footer'
                          : 'hide'
                      }
                    >
                      +{buttoncount}
                    </div>
                    <ClapButton
                      styleType='clap-button'
                      type='button'
                      style={{ width: '96px' }}
                      disabled={clapDisabled}
                      onMouseDown={() => {
                        setMouseDown(true);
                      }}
                      onMouseUp={() => {
                        setMouseDown(false);
                      }}
                    >
                      {parseInt(postclaps) + buttoncount}
                    </ClapButton>
                  </div>
                }
                <div className='tag-links'>
                  {post.tags?.map((tag) => {
                    return (
                      <span
                        key={tag.tagId}
                        className='tag-link'
                        onClick={() => searchTag(tag.tagName)}
                      >
                        {tag.tagName}
                      </span>
                    );
                  })}
                </div>
              </div>

              <div className='about-author'>
                <img
                  className='profile-picture'
                  src={author.avatar || images.DEFAULT_AVATAR}
                  alt=''
                ></img>
                <Link
                  to={`/${author.handle}`}
                  style={{ color: colors.primaryTextColor }}
                  className='handle'
                >
                  @{author.handle}
                </Link>
                <p className='biography'>{author.bio}</p>
              </div>
              <FollowAuthor
                AuthorHandle={author?.handle || ''}
                Followers={user?.followersArray || undefined}
                user={user?.handle || ''}
              />
            </div>
          )}

          {!loading && loadingError && (
            <div className='content'>
              <p className='not-found'>{loadingError}</p>
            </div>
          )}
          <div>
            {screenWidth != 0 && screenWidth < 768 && <EmailOptIn></EmailOptIn>}
          </div>
          <br></br>
          <br></br>
          <br></br>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ReadArticle;
