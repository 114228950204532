export const idlFactory = ({ IDL }) => {
  const TagModel = IDL.Record({
    'id' : IDL.Text,
    'value' : IDL.Text,
    'createdDate' : IDL.Text,
  });
  const Result_5 = IDL.Variant({ 'ok' : TagModel, 'err' : IDL.Text });
  const Result_4 = IDL.Variant({ 'ok' : IDL.Nat, 'err' : IDL.Text });
  const Result = IDL.Variant({ 'ok' : IDL.Null, 'err' : IDL.Text });
  const PostTagModel = IDL.Record({ 'tagId' : IDL.Text, 'tagName' : IDL.Text });
  const Post = IDL.Record({
    'url' : IDL.Text,
    'title' : IDL.Text,
    'created' : IDL.Text,
    'modified' : IDL.Text,
    'content' : IDL.Text,
    'views' : IDL.Text,
    'publishedDate' : IDL.Text,
    'claps' : IDL.Text,
    'tags' : IDL.Vec(PostTagModel),
    'isDraft' : IDL.Bool,
    'handle' : IDL.Text,
    'headerImage' : IDL.Text,
    'subtitle' : IDL.Text,
    'postId' : IDL.Text,
  });
  const Result_1 = IDL.Variant({ 'ok' : Post, 'err' : IDL.Text });
  const Result_3 = IDL.Variant({ 'ok' : IDL.Vec(IDL.Text), 'err' : IDL.Text });
  const Post__1 = IDL.Record({
    'url' : IDL.Text,
    'title' : IDL.Text,
    'created' : IDL.Text,
    'modified' : IDL.Text,
    'content' : IDL.Text,
    'views' : IDL.Text,
    'publishedDate' : IDL.Text,
    'claps' : IDL.Text,
    'tags' : IDL.Vec(PostTagModel),
    'isDraft' : IDL.Bool,
    'handle' : IDL.Text,
    'headerImage' : IDL.Text,
    'subtitle' : IDL.Text,
    'postId' : IDL.Text,
  });
  const GetLatestPostsReturn = IDL.Record({
    'totalCount' : IDL.Text,
    'posts' : IDL.Vec(Post__1),
  });
  const PostTagModel__1 = IDL.Record({
    'tagId' : IDL.Text,
    'tagName' : IDL.Text,
  });
  const Result_2 = IDL.Variant({ 'ok' : IDL.Text, 'err' : IDL.Text });
  const PostTag = IDL.Record({
    'tagId' : IDL.Text,
    'createdDate' : IDL.Int,
    'isActive' : IDL.Bool,
    'modifiedDate' : IDL.Int,
  });
  const UserPostCounts = IDL.Record({
    'totalViewCount' : IDL.Text,
    'uniqueClaps' : IDL.Text,
    'draftCount' : IDL.Text,
    'uniqueReaderCount' : IDL.Text,
    'publishedCount' : IDL.Text,
    'handle' : IDL.Text,
    'totalPostCount' : IDL.Text,
  });
  const PostSaveModel = IDL.Record({
    'title' : IDL.Text,
    'content' : IDL.Text,
    'isDraft' : IDL.Bool,
    'tagIds' : IDL.Vec(IDL.Text),
    'headerImage' : IDL.Text,
    'subtitle' : IDL.Text,
    'postId' : IDL.Text,
  });
  return IDL.Service({
    'clapPost' : IDL.Func([IDL.Text], [], ['oneway']),
    'createTag' : IDL.Func([IDL.Text], [Result_5], []),
    'currentId' : IDL.Func([], [IDL.Nat], ['query']),
    'delete' : IDL.Func([IDL.Text], [Result_4], []),
    'deleteUserPosts' : IDL.Func([IDL.Text], [Result_4], []),
    'dumpIds' : IDL.Func([], [Result], []),
    'dumpPosts' : IDL.Func([], [Result], []),
    'dumpUserIds' : IDL.Func([], [Result], []),
    'followTag' : IDL.Func([IDL.Text], [Result], []),
    'generateLatestPosts' : IDL.Func([], [], []),
    'get' : IDL.Func([IDL.Text], [Result_1], ['query']),
    'getAdmins' : IDL.Func([], [Result_3], ['query']),
    'getAllPostsIncludingDrafts' : IDL.Func([], [IDL.Vec(Post)], ['query']),
    'getAllTags' : IDL.Func([], [IDL.Vec(TagModel)], ['query']),
    'getCgUsers' : IDL.Func([], [Result_3], ['query']),
    'getKinicList' : IDL.Func([], [Result_3], ['query']),
    'getLatestPosts' : IDL.Func(
        [IDL.Nat32, IDL.Nat32],
        [GetLatestPostsReturn],
        ['query'],
      ),
    'getList' : IDL.Func([IDL.Vec(IDL.Text)], [IDL.Vec(Post)], ['query']),
    'getMoreLatestPosts' : IDL.Func(
        [IDL.Nat32, IDL.Nat32],
        [IDL.Vec(Post)],
        ['query'],
      ),
    'getMyPosts' : IDL.Func(
        [IDL.Bool, IDL.Bool, IDL.Nat32, IDL.Nat32],
        [IDL.Vec(Post)],
        ['query'],
      ),
    'getMyTags' : IDL.Func([], [IDL.Vec(PostTagModel__1)], ['query']),
    'getPostUrls' : IDL.Func([], [Result_2], []),
    'getPostsIncludingDrafts' : IDL.Func(
        [IDL.Nat, IDL.Nat],
        [IDL.Vec(Post)],
        ['query'],
      ),
    'getTagsByUser' : IDL.Func([IDL.Text], [IDL.Vec(PostTag)], ['query']),
    'getTotalPostCount' : IDL.Func([], [IDL.Nat], ['query']),
    'getTrustedCanisters' : IDL.Func([], [Result_3], ['query']),
    'getUserPostCounts' : IDL.Func([IDL.Text], [UserPostCounts], ['query']),
    'getUserPosts' : IDL.Func([IDL.Text], [IDL.Vec(Post)], ['query']),
    'latestPostsMigration' : IDL.Func([], [], []),
    'registerAdmin' : IDL.Func([IDL.Text], [Result], []),
    'registerCanister' : IDL.Func([IDL.Text], [Result], []),
    'reindex' : IDL.Func([], [Result_2], []),
    'save' : IDL.Func([PostSaveModel], [Result_1], []),
    'unfollowTag' : IDL.Func([IDL.Text], [Result], []),
    'unregisterAdmin' : IDL.Func([IDL.Text], [Result], []),
    'unregisterCanister' : IDL.Func([IDL.Text], [Result], []),
    'viewPost' : IDL.Func([IDL.Text], [], ['oneway']),
  });
};
export const init = ({ IDL }) => { return []; };
