export const idlFactory = ({ IDL }) => {
  const Result_2 = IDL.Variant({ 'ok' : IDL.Vec(IDL.Text), 'err' : IDL.Text });
  const Result_3 = IDL.Variant({
    'ok' : IDL.Tuple(IDL.Vec(IDL.Principal), IDL.Vec(IDL.Text)),
    'err' : IDL.Text,
  });
  const Result = IDL.Variant({ 'ok' : IDL.Text, 'err' : IDL.Text });
  const Result_1 = IDL.Variant({ 'ok' : IDL.Null, 'err' : IDL.Text });
  const Content = IDL.Record({
    'contentId' : IDL.Text,
    'contentSize' : IDL.Nat,
    'mimeType' : IDL.Text,
    'offset' : IDL.Nat,
    'totalChunks' : IDL.Nat,
    'chunkData' : IDL.Vec(IDL.Nat8),
  });
  const FastBlocks_Storage = IDL.Service({
    'getAdmins' : IDL.Func([], [Result_2], []),
    'getAllDataCanisterIds' : IDL.Func([], [Result_3], []),
    'getCgUsers' : IDL.Func([], [Result_2], ['query']),
    'getNewContentId' : IDL.Func([], [Result], []),
    'registerAdmin' : IDL.Func([IDL.Text], [Result_1], []),
    'retiredDataCanisterIdForWriting' : IDL.Func([IDL.Text], [Result_1], []),
    'unregisterAdmin' : IDL.Func([IDL.Text], [Result_1], []),
    'uploadBlob' : IDL.Func([Content], [Result], []),
  });
  return FastBlocks_Storage;
};
export const init = ({ IDL }) => { return []; };
