import React, { Fragment, useEffect, useState } from 'react';
import { useAuthStore, useUserStore, usePostStore } from '../../store';
import Button from '../../UI/Button/Button';
import InputField2 from '../../UI/InputField2/InputField2';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import MeatBallSidebarArticles from '../../UI/meatball-sidebar-articles/meatball-sidebar-articles';
import UnpublishArticle from '../../UI/unpublish article/unpublish-article';
import QuillTextEditor from '../../components/quill-text-editor/quill-text-editor';
import Loader from '../../UI/loader/Loader';
import { PostSaveModel } from '../../services/actorService';
import {
  formatDate,
  getEmbeddedImages,
  parseEmbeddedImage,
} from '../../shared/utils';

import { getNewContentId, uploadBlob } from '../../services/storageService';
import { downscaleImage } from '../../components/quill-text-editor/modules/quill-image-compress/downscaleImage.js';
import { toast, toastError, ToastType } from '../../services/toastService';
import TagsAutocomplete from '../../components/tags/tags-autocomplete';
import CopyArticle from '../../UI/copy-article/copy-article';
import { useParams, useNavigate } from 'react-router-dom';
import { PostType } from '../../types/types';
import RequiredFieldMessage from '../../components/required-field-message/required-field-message';

import { colors, icons } from '../../shared/constants';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

const CreateEditArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const { getUser, user } = useUserStore((state) => ({
    getUser: state.getUser,
    user: state.user,
  }));

  const {
    savePost,
    getSavedPost,
    clearSavedPost,
    clearSavedPostError,
    savedPost,
    savedPostError,
    getAllTags,
    allTags,
  } = usePostStore((state) => ({
    savePost: state.savePost,
    getSavedPost: state.getSavedPost,
    savedPost: state.savedPost,
    clearSavedPost: state.clearSavedPost,
    savedPostError: state.getSavedPostError,
    clearSavedPostError: state.clearSavedPostError,
    getAllTags: state.getAllTags,
    allTags: state.allTags,
  }));

  const [loading, setLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const [currentPost, setCurrentPost] = useState<PostType>();
  const [postTitle, setPostTitle] = useState('');
  const [postSubTitle, setPostSubTitle] = useState('');
  const [postImage, setPostImage] = useState('');
  const [postHtml, setPostHtml] = useState('');
  const [tags, setPostTags] = useState<string[]>([]);
  const [isDraft, setIsDraft] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [shownMeatball, setShownMeatball] = useState(false);
  const [copyArticle, setCopyArticle] = useState(false);

  //ensures button is clicked prior to warning (turns off all warnings until true)
  const [firstSave, setFirstSave] = useState(false);
  //warniing scenarios decide which fields get the warning box
  const [titleWarning, setTitleWarning] = useState(false);
  const [introWarning, setIntroWarning] = useState(false);
  const [bodyWarning, setBodyWarning] = useState(false);
  const [tagsWarning, setTagsWarning] = useState(false);

  const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);

  useEffect(() => {
    clearAll();
    window.scrollTo(0, 0);
    getUser();
    if (!allTags?.length) {
      getAllTags();
    }
    return () => {
      clearAll();
    };
  }, []);

  useEffect(() => {
    window.onresize = window.onload = () => {
      setScreenWidth(window.innerWidth);
    };
  }, [screenWidth]);

  useEffect(() => {
    if (isLoggedIn && !user) {
      navigate('/', { replace: true });
    }
  }, [isLoggedIn, user]);

  useEffect(() => {
    if (id) {
      if (isNaN(parseInt(id))) {
        navigate('/article/new', { replace: true });
      } else if (id !== savedPost?.postId) {
        getSavedPost(id);
      }
    }
  }, [id]);

  useEffect(() => {
    if (savedPostError) {
      clearSavedPostError();
      navigate('/article/new', { replace: true });
    }
  }, [savedPostError]);

  useEffect(() => {
    if (savedPost) {
      setCurrentPost(savedPost);
    }
  }, [savedPost]);

  useEffect(() => {
    if (currentPost) {
      setFields(currentPost);
      setLoading(false);
    } else {
      clearFields();
    }
  }, [currentPost]);

  function validate() {
    const tagIds = getTagIds();
    const isEditMode = !!id;
    const userIsAuthor =
      user?.handle?.toLowerCase() === currentPost?.handle?.toLowerCase();
    const unauthorized = isEditMode && !userIsAuthor;
    const isValid =
      !loading &&
      !unauthorized &&
      postTitle.trim() !== '' &&
      postSubTitle !== '' &&
      postHtml !== '' &&
      tagIds.length !== 0 &&
      !titleWarning &&
      !introWarning &&
      !bodyWarning &&
      !tagsWarning;
    return isValid;
  }

  useEffect(() => {
    setTitleWarning(firstSave && postTitle === '');
  }, [postTitle, firstSave]);

  useEffect(() => {
    setIntroWarning(firstSave && postSubTitle === '');
  }, [postSubTitle, firstSave]);

  useEffect(() => {
    setBodyWarning(firstSave && postHtml === '');
  }, [postHtml, firstSave]);

  useEffect(() => {
    setTagsWarning(firstSave && tags.length === 0);
  }, [tags, firstSave]);

  const onPostTitleChange = (value: string) => {
    setPostTitle(value);
  };

  const onPostSubTitleChange = (value: string) => {
    setPostSubTitle(value);
  };

  const onPostTextChange = (html: string, text: string, isEmpty: boolean) => {
    setPostHtml(isEmpty ? '' : html);
  };

  const onPostTagChange = (value: string[]) => {
    setPostTags(value);
  };

  const onTagsValidationError = (errorMessage: string) => {
    toast(errorMessage, ToastType.Plain);
  };

  const onPostImageChange = (e: any) => {
    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
    reader.onload = async (event) => {
      const embeddedImage = event?.target?.result as string;
      const dataUrlCompressed = await downscaleImage(
        embeddedImage,
        1000, // max width
        1000, // max height
        'image/jpeg', // all images converted to jpeg
        [], // keepImageTypes
        [], // ignoreImageTypes
        0.9, // image quality
        console
      );
      setPostImage(dataUrlCompressed);
    };
  };

  const convertImagesToUrls = async (
    content: string
  ): Promise<{ headerUrl: string; contentWithUrls: string } | null> => {
    let headerUrl = postImage;
    // returns null if the header image is already a URL
    const headerImage = parseEmbeddedImage(postImage);
    const images = getEmbeddedImages(content);

    // Validate that the blob size of every image is less than
    // the max allowed bytes for an IC ingress message (2 MB).
    // Subtract 1 KB for additional payload data.
    const maxMessageSize = 1024 * 1024 * 2 - 1024; //2096640 bytes
    let errorImageName = '';

    if ((headerImage?.blob.size || 0) > maxMessageSize) {
      errorImageName = 'Header image';
    } else {
      const imageIndex = images.findIndex(
        (image) => image.blob.size > maxMessageSize
      );

      if (imageIndex > -1) {
        errorImageName = `Content image # ${imageIndex + 1}`;
      }
    }

    if (errorImageName) {
      toast(
        `${errorImageName} exceeded the maximum image size of ` +
          `${(maxMessageSize / 1024 / 1024).toFixed(3)} MBs after compression.`,
        ToastType.Error
      );

      return null;
    }

    // TODO: Remove temporary hack when parallel uploads are working without this.
    // Each call to the canister is 2 seconds, so the header image + 2 content images
    // will take 6 seconds just to get content ids, before uploading begins.
    if (headerImage) {
      headerImage.contentId = await getNewContentId();
    }
    for (let image of images) {
      image.contentId = await getNewContentId();
    }

    const promises = images.map((image) =>
      uploadBlob(
        image.blob,
        image.blob.size,
        image.mimeType,
        image.index.toString(),
        image.contentId
      )
    );

    if (headerImage) {
      promises.push(
        uploadBlob(
          headerImage.blob,
          headerImage.blob.size,
          headerImage.mimeType,
          '-1', // indicates header
          headerImage.contentId
        )
      );
    }

    let storageInfo = await Promise.all(promises);

    if (headerImage) {
      let headerImageStorageInfo = storageInfo.find(
        (info) => info.mappingId === '-1'
      );
      if (headerImageStorageInfo?.dataUrl) {
        headerUrl = headerImageStorageInfo.dataUrl;
      }
      storageInfo = storageInfo.filter((info) => info.mappingId !== '-1');
    }

    storageInfo.sort((a, b) => Number(a.mappingId) - Number(b.mappingId));

    let offset = 0;
    let c = content;
    for (const info of storageInfo) {
      const image = images.find((x) => x.index === Number(info.mappingId));
      if (image) {
        const start = image.index + offset;
        const end = start + image.length;
        const replacement = `"${info.dataUrl}"`; // could add additional attributes

        // replace base64 with url
        c = c.substring(0, start) + replacement + c.substring(end);

        offset += replacement.length - image.length;
      }
    }

    return { headerUrl, contentWithUrls: c };
  };

  const getTagIds = (): string[] => {
    let tagIds: string[] = [];
    if (tags?.length && allTags?.length) {
      tagIds = tags
        .map((tagName) => {
          let tag = allTags.find((t) => t.value === tagName);
          return tag ? tag.id : '';
        })
        .filter((tagId) => tagId !== '');
    }

    return tagIds;
  };

  const onSave = async (draft: boolean = true) => {
    setFirstSave(true);
    setSaveBtnIsDisabled(true);
    const isValid = validate();
    if (!isValid) {
      setSaveBtnIsDisabled(false);
      return;
    }

    setIsDraft(draft);

    setLoading(true);

    // ensure the spinner does not get stuck
    setTimeout(() => {
      setLoading(false);
    }, 10000);

    try {
      let tagIds = getTagIds();
      if (tagIds.length == 0) {
        throw new Error('At least 1 tag is required.');
      } else if (tagIds.length > 3) {
        throw new Error('A maximum of 3 tags can be added.');
      }

      const result = await convertImagesToUrls(postHtml);
      if (result) {
        setPostImage(result.headerUrl);
        setPostHtml(result.contentWithUrls);

        const postToSave: PostSaveModel = {
          postId: currentPost?.postId || '',
          title: postTitle,
          subtitle: postSubTitle,
          headerImage: result.headerUrl,
          content: result.contentWithUrls,
          isDraft: draft,
          tagIds,
        };

        savePost(postToSave);
        setTimeout(() => {
          setSaveBtnIsDisabled(false);
        }, 2000);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const onPublish = async () => {
    onSave(false);
  };

  const setFields = (post: PostType) => {
    setPostTitle(post.title);
    setPostSubTitle(post.subtitle);
    setPostImage(post.headerImage);
    setPostHtml(post.content);
    setIsDraft(post.isDraft);
    setPostTags((post.tags || []).map((t) => t.tagName));
  };

  const clearFields = () => {
    setPostTitle('');
    setPostSubTitle('');
    setPostImage('');
    setPostHtml('');
    setIsDraft(true);
    setPostTags([]);
  };

  const clearAll = () => {
    clearSavedPost();
    clearSavedPostError();
    setCurrentPost(undefined);
    clearFields();
  };

  const KeyCodes = {
    comma: 188,
    enter: [10, 13],
  };

  const delimiters = [...KeyCodes.enter, KeyCodes.comma];

  return (
    <Fragment>
      <Header
        loggedIn={isLoggedIn}
        isArticlePage={true}
        ScreenWidth={screenWidth}
        tokens={user?.nuaTokens}
        loading={false}
      />
      <div className='create-edit-article-wrapper'>
        <div className='left'>
          <div className='left-content'>
            <p className='date'>
              {formatDate(
                currentPost?.modified || new Date().getTime().toString()
              )}
            </p>
            <div className='menus'>
              {currentPost?.url && (
                <CopyArticle
                  url={currentPost.url}
                  shown={copyArticle}
                  setShown={setCopyArticle}
                />
              )}
              {currentPost && !currentPost.isDraft && (
                <UnpublishArticle
                  shown={shownMeatball}
                  setIsDraft={setIsDraft}
                  setShown={setShownMeatball}
                  savePost={() => onSave()}
                />
              )}
            </div>
            <div className='horizontal-divider'></div>

            <p className='left-text'>
              last modified: {formatDate(currentPost?.modified) || ' - '}
            </p>

            <div className={isDraft ? '' : 'hideUpdateButton'}>
              {location.pathname == '/article/new' || isDraft ? (
                <Button
                  disabled={saveBtnIsDisabled}
                  type='button'
                  styleType='primary-1'
                  style={{ width: '96px', margin: '10px 0' }}
                  onClick={() => onSave()}
                >
                  {isDraft ? 'Save' : 'Update'}
                </Button>
              ) : null}
            </div>

            <div className='horizontal-divider'></div>

            <p className='left-text'>
              Current status: {isDraft ? 'Draft' : 'Published'}
            </p>

            {/* {isDraft && ( */}
            <Button
              type='button'
              styleType='primary-1'
              style={{ width: '96px' }}
              onClick={() => onPublish()}
            >
              Publish
            </Button>
            {/* )} */}
          </div>
          <div className='left-sidebar'>
            <MeatBallSidebarArticles
              isDraft={isDraft}
              isDisabled={isDisabled}
              onSave={onSave}
              onPublish={onPublish}
              post={currentPost}
            />
          </div>
        </div>

        <div className='vertical-divider'></div>
        <div className='right'>
          {loading && <Loader />}
          <div
            className='right-position'
            style={{ display: loading ? 'none' : 'inherit' }}
          >
            {screenWidth != 0 && screenWidth < 768 && (
              <p className='right-text'>
                last modified: {formatDate(currentPost?.modified) || ' - '}
              </p>
            )}
            <InputField2
              width='100%'
              height='53px'
              defaultText='Title...'
              fontSize='48px'
              fontFamily='Georgia'
              fontColor={colors.primaryTextColor}
              hasError={titleWarning}
              value={postTitle}
              onChange={onPostTitleChange}
            ></InputField2>
            <div style={{ position: 'relative', top: '-20px' }}>
              {<RequiredFieldMessage hasError={titleWarning} />}
            </div>

            <InputField2
              width='100%'
              height='24px'
              defaultText='Intro...'
              fontSize='22px'
              fontFamily='Roboto'
              fontColor={colors.darkerBorderColor}
              hasError={introWarning}
              value={postSubTitle}
              onChange={onPostSubTitleChange}
            ></InputField2>
            <div style={{ position: 'relative', top: '-20px' }}>
              {<RequiredFieldMessage hasError={introWarning} />}
            </div>

            <input
              id='file'
              type='file'
              style={{ display: 'none' }}
              required
              onChange={onPostImageChange}
            />
            {postImage ? (
              <label htmlFor='file' className='uploaded-pic'>
                <img
                  className='uploaded-pic'
                  src={postImage}
                  alt='background'
                  onChange={onPostImageChange}
                />
              </label>
            ) : (
              <label htmlFor='file' className='upload-picture'>
                <FontAwesomeIcon style={{color: colors.accentColor}} icon={faCamera}/>
                {/* <img src={icons.UPLOAD_PICTURE} alt='background' /> */}
              </label>
            )}

            <QuillTextEditor
              onChange={onPostTextChange}
              value={postHtml}
              hasError={bodyWarning}
            />
            {<RequiredFieldMessage hasError={bodyWarning} />}

            <TagsAutocomplete
              placeholder='Tag ...'
              value={tags}
              options={(allTags || []).map((tag) => tag.value)}
              maxAllowedTags={3}
              onChange={onPostTagChange}
              onValidationError={onTagsValidationError}
              hasError={tagsWarning}
            />
            {<RequiredFieldMessage hasError={tagsWarning} />}

            {screenWidth != 0 && screenWidth < 768 && (
              <div className='mobile-footer'>
                <p className='left-text'>
                  last modified: {formatDate(currentPost?.modified) || ' - '}
                </p>

                <div className={isDraft ? '' : 'hideUpdateButton'}>
                  {location.pathname == '/article/new' ? (
                    <Button
                      type='button'
                      // icon={NONAME}
                      styleType='primary-1'
                      style={{ width: '96px', margin: '10px 0' }}
                      onClick={() => onSave()}
                    >
                      {isDraft ? 'Save' : 'Update'}
                    </Button>
                  ) : null}
                </div>

                <div className='horizontal-divider'></div>

                <p className='left-text'>
                  Current status: {isDraft ? 'Draft' : 'Published'}
                </p>

                {/* {isDraft && ( */}
                <Button
                  type='button'
                  // icon={NONAME}
                  styleType='primary-1'
                  style={{ width: '96px' }}
                  onClick={() => onPublish()}
                  //disabled={isDisabled}
                >
                  Publish
                </Button>
                {/* )} */}
                <br></br>
                <br></br>
              </div>
            )}
            <Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateEditArticle;
