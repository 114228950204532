import React from 'react';

type InputProps = {
  handle: Boolean;
  onChange?: (searchTerm: string) => void;
  hasError: Boolean;
};

const InputField: React.FC<InputProps> = ({
  handle,
  hasError,
  onChange,
}): JSX.Element => {
  const className = hasError
    ? 'textarea has-error input-attributes'
    : 'textarea';
  return (
    <div>
      <div className='input-attributes'>
        <span
          className={className}
          id={handle ? 'handle' : 'no-handle'}
          role='input'
          contentEditable
          onInput={(e: React.ChangeEvent<HTMLInputElement>): void => {
            onChange && onChange(String(e.currentTarget.textContent));
            // console.log('test: ', e.currentTarget.textContent);
          }}
        ></span>
      </div>
    </div>
  );
};

export default InputField;
