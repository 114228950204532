import React, {useState} from 'react';
import { icons, colors } from '../../shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

type CopyProfileProps = {
  shown: boolean;
  setShown: React.Dispatch<React.SetStateAction<boolean>>;
  handle: string | undefined;
};

const CopyProfile: React.FC<CopyProfileProps> = (props): JSX.Element => {
  const CloseMenu = () => {
    props.setShown(false);
  };

  const copyLinkToProfile = () => {
    navigator.clipboard.writeText(`${window.location.origin}/${props.handle}`);
    setTimeout(() => {
      CloseMenu();
    }, 2000);
  };

  const [hover, setHover] = useState(false);

  return (
    <div className='meatball-menu'>
      <FontAwesomeIcon
      icon={faLink}
        onClick={() => {
          props.setShown(!props.shown), copyLinkToProfile();
        }}
        // src={props.shown ? icons.COPY_BLUE : icons.COPY}
        // alt='copy-article-menu'
        style={hover ? { cursor: 'pointer', color: colors.accentColor } : {color: colors.primaryColor}}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />

      <div
        className='left-drop-down-content'
        style={
          props.shown
            ? {
                height: 82,
                boxShadow: '0px 2px 10px 5px rgba(117, 117, 117, 0.08)',
              }
            : { height: 0 }
        }
      >
        <ul style={props.shown ? {} : { display: 'none' }}>
          <div onClick={copyLinkToProfile}>
            <a>
              <li style={{ cursor: 'hand' }}>Link copied!</li>
            </a>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default CopyProfile;
