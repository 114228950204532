import React, { useEffect } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import HomePageGrid from './screens/home/homegrid';
import TimedOut from './screens/timed-out/timedOut';
import LoginRegistration from './screens/login-registration/login-registration';
import CreateEditArticle from './screens/create-edit-article/create-edit-article';
import ReadArticle from './screens/read-article/read-article';
import ProfileSidebar from './screens/profile/profileSidebar';
import MyProfile from './screens/profile/myProfile';
import EditProfile from './screens/profile/editProfile';
import DraftArticles from './screens/profile/draftArticles';
import PublishedArticles from './screens/profile/publishedArticles';
import Profile from './screens/profile/profile';
import { useAuthStore } from './store';
import { useIdleTimer } from 'react-idle-timer';
import FollowedTags from './screens/profile/followedTags';
import Following from './screens/profile/following';

const Routes = () => {
  return useRoutes([
    { path: '/', element: <HomePageGrid /> },
    { path: '/timed-out', element: <TimedOut /> },
    { path: '/register', element: <LoginRegistration /> },
    { path: '/:handle', element: <Profile /> },
    { path: '/:handle/:id/:title', element: <ReadArticle /> },
    { path: '/article/new', element: <CreateEditArticle /> },
    { path: '/article/edit/:id', element: <CreateEditArticle /> },
    { path: '/subscription', element: <HomePageGrid /> },
    {
      path: '/my-profile',
      element: <ProfileSidebar />,
      children: [
        { path: '', element: <MyProfile /> },
        { path: 'edit', element: <EditProfile /> },
        { path: 'draft', element: <DraftArticles /> },
        { path: 'published', element: <PublishedArticles /> },
        { path: 'topics', element: <FollowedTags /> },
        { path: 'following', element: <Following /> },
      ],
    },
  ]);
};

const siteTitle = 'FastBlocks';
const siteDesc = 'Smart Web3 Stories';
const logo = `${window.location.origin}/logo.png`;

function App() {
  const inactivityTimeout: number = process.env.II_INACTIVITY_TIMEOUT
    ? // configuration is in minutes, but API expects milliseconds
      Number(process.env.II_INACTIVITY_TIMEOUT) * 60 * 1_000
    : // default = 1 hour
      60 * 60 * 1_000;

  const { isLoggedIn, logout } = useAuthStore((state) => ({
    isLoggedIn: state.isLoggedIn,
    logout: state.logout,
  }));

  const onIdle = () => {
    console.log('Idle: ' + new Date());
    if (isLoggedIn) {
      logout();
      console.log('Logged out: ' + new Date());
      window.location.href = '/timed-out';
    }
  };

  const idleTimer = useIdleTimer({
    timeout: inactivityTimeout,
    onIdle,
    crossTab: true,
    startManually: true,
  });

  useEffect(() => {
    if (isLoggedIn) {
      idleTimer.start();
    } else {
      idleTimer.pause();
    }
  }, [isLoggedIn]);

  return (
    <div className='App'>
      <Helmet>
        <meta charSet='utf-8' />
        <link rel='canonical' href={window.location.href} />
        <link rel='icon' href='/favicon.ico' type='image/x-icon' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />

        {/* HTML Meta Tags */}
        <title>{siteTitle}</title>
        <meta name='description' content={siteDesc} />

        {/* Google / Search Engine Tags */}
        <meta itemProp='name' content={siteTitle} />
        <meta itemProp='description' content={siteDesc} />
        <meta itemProp='image' content={logo} />

        {/* Facebook Meta Tags */}
        <meta property='og:url' content={window.location.href} />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={siteTitle} />
        <meta property='og:description' content={siteDesc} />
        <meta property='og:image' content={logo} />

        {/* Twitter Meta Tags */}
        <meta name='twitter:card' content={logo} />
        <meta name='twitter:title' content={siteTitle} />
        <meta name='twitter:description' content={siteDesc} />
        <meta name='twitter:image' content={logo} />
      </Helmet>
      <Router>
        <Routes />
      </Router>
      <Toaster />
    </div>
  );
}

export default App;
