import create, { GetState, SetState, StateCreator, StoreApi } from 'zustand';
import { persist } from 'zustand/middleware';
import { AuthClient, AuthClientLoginOptions } from '@dfinity/auth-client';
import { Principal } from '@dfinity/principal';
import { Ed25519KeyIdentity } from '@dfinity/identity';
//import { Usergeek } from 'usergeek-ic-js';
import { Identity } from '@dfinity/agent';
import { toastError } from '../services/toastService';
import { useUserStore, usePostStore } from './';
import { StoicIdentity } from 'ic-stoic-identity';

// configuration
const identityProvider: string =
  process.env.II_PROVIDER_URL || 'https://identity.ic0.app/#authorize';

const sessionTimeout: BigInt = process.env.II_SESSION_TIMEOUT
  ? // configuration is in minutes, but API expects nanoseconds
    BigInt(process.env.II_SESSION_TIMEOUT) * BigInt(60) * BigInt(1_000_000_000)
  : // default = 8 hours
    BigInt(8) * BigInt(60) * BigInt(60) * BigInt(1_000_000_000);

const fakeProvider: boolean = process.env.II_PROVIDER_USE_FAKE == 'true';

var authClient: AuthClient;

export interface AuthStore {
  readonly isInitialized: boolean;
  readonly isLoggedIn: boolean;
  readonly registrationError: string | undefined;
  readonly loginMethod: string | undefined;
  login: (loginMethod: string) => Promise<void>;
  logout: () => Promise<void>;
  getIdentity: () => Promise<Identity | undefined>;

  clearAll: () => void;
  clearLoginMethod: () => void;
}

// Encapsulates and abstracts AuthClient
// identity has a value when authenticated, otherwise it's undefined
// to get the principal, use identity?.getPrincipal()
const createAuthStore: StateCreator<AuthStore> | StoreApi<AuthStore> = (
  set,
  get
) => ({
  isInitialized: false,
  isLoggedIn: false,
  registrationError: undefined,
  loginMethod: undefined,

  login: async (_loginMethod: string): Promise<void> => {
    set({ registrationError: undefined, isLoggedIn: false });
    if (_loginMethod === 'stoic') {
      let identity = await StoicIdentity.connect();
      set({ isLoggedIn: true, loginMethod: 'stoic' });
      //Usergeek.setPrincipal(identity.getPrincipal());
      //Usergeek.trackSession();
      await useUserStore.getState().getUser();
      if (useUserStore.getState().user === undefined) {
        //check for brave browser
        const navigator = window.navigator as any;
        (navigator.brave && (await navigator.brave.isBrave())) || false
          ? alert(
              'Must enable all cookies for Stoic wallet to work with Brave browser'
            )
          : console.log('Not a brave browser');
        window.location.href = '/register';
      }
    } else if (_loginMethod === 'ii') {
      StoicIdentity.disconnect();
      set({ loginMethod: 'ii' });
      if (!(await authClient?.isAuthenticated())) {
        if (fakeProvider) {
          // for local development, the identity can be generated
          // bypassing the Internet Identity login workflow
          console.log('creating fake provider');
          authClient = await AuthClient.create({
            identity: Ed25519KeyIdentity.generate(),
          });
          set({ isLoggedIn: true });
          await useUserStore.getState().getUser();
        } else {
          await authClient.login(<AuthClientLoginOptions>{
            onSuccess: async () => {
              console.log('Logged in: ' + new Date());
              set({ isLoggedIn: true });

              //Usergeek.setPrincipal(authClient.getIdentity().getPrincipal());
              //Usergeek.trackSession();

              await useUserStore.getState().getUser();
              if (useUserStore.getState().user === undefined) {
                window.location.href = '/register';
              } else {
                window.location.href = '/';
              }
            },
            onError: (error) => {
              set({ isLoggedIn: false, registrationError: error });
              toastError(error);
              if (useUserStore.getState().user === undefined) {
                window.location.href = '/register';
              }
            },
            identityProvider,
            maxTimeToLive: sessionTimeout,
          });
        }
      } else {
        set({ isLoggedIn: true });
        await useUserStore.getState().getUser();
        if (useUserStore.getState().user === undefined) {
          window.location.href = '/register';
        }
      }
    }
  },

  logout: async () => {
    let loginMethod = useAuthStore?.getState().loginMethod;
    if (!fakeProvider && loginMethod === 'ii') {
      //Usergeek.setPrincipal(Principal.anonymous());
      await authClient.logout();
    } else if (loginMethod === 'stoic') {
      StoicIdentity.disconnect();
      //Usergeek.setPrincipal(Principal.anonymous());
    }
    set({ isLoggedIn: false });
    // clear all stores, and sessionStorage
    usePostStore.getState().clearAll();
    useUserStore.getState().clearAll();
    get().clearAll();
    sessionStorage?.clear();
  },

  getIdentity: async (): Promise<Identity | undefined> => {
    let stcIdentity = await StoicIdentity.load();
    if (stcIdentity) {
      set({ isLoggedIn: true, loginMethod: 'stoic' });
      return stcIdentity;
    } else {
      try {
        if (!authClient) {
          authClient = await AuthClient.create();

          let isLoggedIn = await authClient.isAuthenticated();
          set({ isInitialized: true, isLoggedIn });
          if (isLoggedIn) {
            set({ loginMethod: 'ii' });
          }
          //no need to await and block the thread while app loads
          useUserStore.getState().getUser();
        }
      } catch (err) {
        toastError(err);
      }
      return authClient?.getIdentity();
    }
  },

  clearAll: (): void => {
    set({}, true);
  },

  clearLoginMethod: (): void => {
    //stoic continues to connect and reconnect so it needs to be disconnected to login to II
    StoicIdentity.disconnect();
  },
});

export const useAuthStore = create<AuthStore>(
  persist(
    (set, get, api) => ({
      ...createAuthStore(
        set as SetState<AuthStore>,
        get as GetState<AuthStore>,
        api as StoreApi<AuthStore>
      ),
    }),
    {
      name: 'authStore',
      getStorage: () => sessionStorage,
    }
  )
);
