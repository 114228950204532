export const idlFactory = ({ IDL }) => {
  const List = IDL.Rec();
  const Result_4 = IDL.Variant({ 'ok' : IDL.Text, 'err' : IDL.Text });
  const Result_3 = IDL.Variant({ 'ok' : IDL.Nat, 'err' : IDL.Text });
  List.fill(IDL.Opt(IDL.Tuple(IDL.Text, List)));
  const Followers = IDL.Opt(IDL.Tuple(IDL.Text, List));
  const User = IDL.Record({
    'bio' : IDL.Text,
    'followersArray' : IDL.Vec(IDL.Text),
    'displayName' : IDL.Text,
    'nuaTokens' : IDL.Float64,
    'accountCreated' : IDL.Text,
    'handle' : IDL.Text,
    'followers' : Followers,
    'avatar' : IDL.Text,
  });
  const Result = IDL.Variant({ 'ok' : User, 'err' : IDL.Text });
  const Result_2 = IDL.Variant({ 'ok' : IDL.Vec(IDL.Text), 'err' : IDL.Text });
  const NuaBalanceResult = IDL.Variant({ 'ok' : IDL.Text, 'err' : IDL.Text });
  const UserListItem = IDL.Record({ 'handle' : IDL.Text, 'avatar' : IDL.Text });
  const Result_1 = IDL.Variant({ 'ok' : IDL.Null, 'err' : IDL.Text });
  return IDL.Service({
    'addNuaBalance' : IDL.Func([IDL.Text], [], ['oneway']),
    'adminAirDrop' : IDL.Func([IDL.Float64], [Result_4], []),
    'deleteUser' : IDL.Func([IDL.Text], [Result_3], []),
    'dumpUsers' : IDL.Func([], [IDL.Text], ['query']),
    'followAuthor' : IDL.Func([IDL.Text], [Result], []),
    'getAdmins' : IDL.Func([], [Result_2], ['query']),
    'getCgUsers' : IDL.Func([], [Result_2], ['query']),
    'getNuaBalance' : IDL.Func([IDL.Text], [NuaBalanceResult], ['query']),
    'getTrustedCanisters' : IDL.Func([], [Result_2], ['query']),
    'getUser' : IDL.Func([], [Result], ['query']),
    'getUserByHandle' : IDL.Func([IDL.Text], [Result], ['query']),
    'getUserByPrincipalId' : IDL.Func([IDL.Text], [Result], ['query']),
    'getUserInternal' : IDL.Func([IDL.Text], [IDL.Opt(User)], ['query']),
    'getUsersByHandles' : IDL.Func(
        [IDL.Vec(IDL.Text)],
        [IDL.Vec(UserListItem)],
        ['query'],
      ),
    'handleClap' : IDL.Func([IDL.Text, IDL.Text], [], ['oneway']),
    'registerAdmin' : IDL.Func([IDL.Text], [Result_1], []),
    'registerCanister' : IDL.Func([IDL.Text], [Result_1], []),
    'registerUser' : IDL.Func([IDL.Text, IDL.Text, IDL.Text], [Result], []),
    'spendNuaBalance' : IDL.Func([IDL.Text], [], ['oneway']),
    'unfollowAuthor' : IDL.Func([IDL.Text], [Result], []),
    'unregisterAdmin' : IDL.Func([IDL.Text], [Result_1], []),
    'unregisterCanister' : IDL.Func([IDL.Text], [Result_1], []),
    'updateAvatar' : IDL.Func([IDL.Text], [Result], []),
    'updateBio' : IDL.Func([IDL.Text], [Result], []),
    'updateDisplayName' : IDL.Func([IDL.Text], [Result], []),
  });
};
export const init = ({ IDL }) => { return []; };
