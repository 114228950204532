import React, { useEffect, useState } from 'react';
import { icons } from '../../shared/constants';

type ButtonProps = {
  type?: String;
  styleType?: String;
  icon?: String;
  style?: Object;
  onClick?: (event: any) => void;
  disabled?: boolean;
  onMouseDown: (event: any) => void;
  onMouseUp: (event: any) => void;
};

const ClapButton: React.FC<ButtonProps> = (props): JSX.Element => {
  const { styleType, icon, children, type, disabled, style, onClick } = props;

  let clapIcon = icons.CLAP_BLUE;
  const [clapIcons, setClapIcons] = useState([clapIcon]);
  const [clicks, setClicks] = useState(0);

  function clapAnimation() {
    setClicks(clicks + 1);
    setClapIcons(clapIcons.concat(clapIcon));
    setTimeout(() => {
      setClicks(0);
      //keep from growing too large but leaves room for fast clicks
      if (clapIcons.length > 3) {
        setClapIcons([clapIcon]);
      }
    }, 250);
  }

  function clapCreate() {
    for (let i = 0; i < clicks; i++) {
      return (
        <img
          src={clapIcons[clicks]}
          alt='clap'
          className='clap-icon-container'
        />
      );
    }
  }

  useEffect(() => {
    clapCreate();
  }, [clicks]);

  return (
    <button
      className={'button-attributes-' + styleType}
      style={style}
      onClick={clapAnimation}
      disabled={disabled ? true : false}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
    >
      {clapCreate()}
      {icon ? <img className='plus-sign' src={String(icon)} /> : ''}
      {children}
    </button>
  );
};

export default ClapButton;
