import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useUserStore } from '../../store';
import { CardVerticalProps } from '../card-vertical/types';
import { DateFormat, formatDate } from '../../shared/utils';
import { images, icons } from '../../shared/constants';

const CardDraftArticles: React.FC<CardVerticalProps> = ({ post }) => {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  return (
    <div className='card-wrapper-horizontal'>
      <img
        className='main-pic-horizontal'
        src={post.headerImage || images.NUANCE_LOGO}
        alt='Article header image'
        onClick={() => navigate(post.url)}
      />
      <div className='horizontal'>
        <div className='publisher'>
          <div className='card-creator-horizontal'>
            {user && (
              <>
                <div className='left-card-icons'>
                  <Link to={`/article/edit/${post.postId}`}>
                    <img className='profile-pic pencil-icon' src={icons.EDIT} alt='' />
                  </Link>
                </div>
              </>
            )}
            <div className='published-date'>
              <span>|</span>
              <p>{formatDate(post.modified, DateFormat.NoYear)}</p>
            </div>
          </div>
        </div>
        <div className='article-text'>
          <Link to={post.url}>
            <h2>{post.title}</h2>
          </Link>
          <p>{post.subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default CardDraftArticles;
