import React, { useState, useEffect } from 'react';
import Button from '../../UI/Button/Button';
import { useNavigate } from 'react-router';
import InputField from '../../UI/InputField2/InputField2';
import { useUserStore, useAuthStore } from '../../store';
import { base64toBlob, formatDate } from '../../shared/utils';
import { uploadBlob } from '../../services/storageService';
import { images, icons, colors } from '../../shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

const EditProfile = () => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const navigate = useNavigate();
  const { user, updateBio, updateDisplayName, updateAvatar } = useUserStore(
    (state) => ({
      user: state.user,
      updateBio: state.updateBio,
      updateDisplayName: state.updateDisplayName,
      updateAvatar: state.updateAvatar,
    })
  );

  useEffect(() => {
    if (isLoggedIn && !user) {
      navigate('/', { replace: true });
    }
  }, [isLoggedIn, user]);

  const [displayName, setDisplayName] = useState(user?.displayName);
  const [biography, setBiography] = useState(user?.bio);

  const [avatar, setAvatar] = useState(user?.avatar || images.DEFAULT_AVATAR);
  const [avatarBlob, setAvatarBlob] = useState(new Blob());
  const [avatarMimeType, setAvatarMimeType] = useState('');
  const [avatarSize, setAvatarSize] = useState(0);
  const [avatarChanged, setAvatarChanged] = useState(false);
  const [hover, setHover] = useState(false);

  const onDisplayNameChange = (value: string) => {
    setDisplayName(value);
  };

  const onBiographyChange = (value: string) => {
    setBiography(value);
  };

  const onAvatarChange = (e: any) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.readAsDataURL(file);
    }
    reader.onloadend = () => {
      if (reader.result === null) {
        throw new Error('file empty...');
      }
      const data = typeof reader.result == 'string' ? reader.result : null;
      setAvatar(data as string);
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      const blob = base64toBlob(encoded, file.type);
      setAvatarBlob(blob);
      setAvatarMimeType(file.type);
      setAvatarSize(file.size);
      setAvatarChanged(true);
    };
  };

  const onSave = async () => {
    if (user?.displayName !== displayName) {
      await updateDisplayName(displayName as string);
    }

    if (user?.bio !== biography) {
      await updateBio(biography as string);
    }

    if (avatarChanged) {
      const contentInfo = await uploadBlob(
        avatarBlob,
        avatarSize,
        avatarMimeType
      );
      await updateAvatar(contentInfo.dataUrl);
      setAvatarChanged(false);
    }
    navigate('/my-profile');
  };

  return (
    <div className='wrapper edit'>
      <p className='title'>MY PROFILE / EDIT PROFILE</p>

      <div className='image'>
        <div className='edit'>
          <label htmlFor='file'>
            <FontAwesomeIcon
            icon={faCamera}
              // alt=''
              // src={icons.CHANGE_PHOTO}
              onChange={onAvatarChange}
              style={hover ? { cursor: 'pointer', color: colors.accentColor } : {color: colors.primaryColor}}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            />
          </label>
          <input
            id='file'
            type='file'
            style={{ display: 'none' }}
            required
            onChange={onAvatarChange}
          />
        </div>

        <label htmlFor='file'>
          {avatar ? (
            <img src={avatar} alt='background' onChange={onAvatarChange} />
          ) : (
            <img alt='background' src={images.AIKIN_LOGO}></img>
          )}
        </label>
      </div>
      <div className='section'>
        <div className='row'>
          <div className='entry'>
            <p className='label'>ACCOUNT CREATED</p>
            <p className='value'>{formatDate(user?.accountCreated)}</p>
          </div>
          <div className='entry'>
            <p className='label'>@HANDLE</p>
            <p className='value'>@{user?.handle}</p>
          </div>
        </div>
        <div className='entry'>
          <p className='label' style={{ marginTop: '10px' }}>
            DISPLAY NAME
          </p>
          <InputField
            defaultText='Enter display name...'
            width='100%'
            height='50px'
            fontSize={'14px'}
            fontFamily='Roboto'
            fontColor={colors.editProfileInputTextColor}
            hasError={false}
            onChange={onDisplayNameChange}
            value={displayName}
          ></InputField>
        </div>
      </div>
      <div className='section'>
        <div className='entry'>
          <p className='label'>BIOGRAPHY</p>
          <InputField
            defaultText='Enter biography...'
            width='100%'
            height='50px'
            fontSize={'14px'}
            fontFamily='Roboto'
            fontColor={colors.editProfileInputTextColor}
            hasError={false}
            onChange={onBiographyChange}
            value={biography}
            maxLength={160}
          ></InputField>
        </div>
      </div>
      <p className='subtitle'>
        Your bio appears on your profile next to your stories. Max 160
        characters.
      </p>
      <div className='controls'>
        <Button
          style={{ width: '96px', margin: '0 16px 0 0' }}
          type='button'
          styleType='secondary-1'
          onClick={() => navigate('/my-profile')}
        >
          Cancel
        </Button>
        <Button
          onClick={onSave}
          type='button'
          styleType='primary-1'
          style={{ width: '96px' }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditProfile;
