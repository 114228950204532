import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAuthStore, useUserStore, usePostStore } from '../../store';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Button from '../../UI/Button/Button';
import CardHorizontal from '../../components/card-horizontal/card-horizontal';
import ReportAuthorMenu from '../../UI/report-author/report-author';
import CopyProfile from '../../UI/copy-profile/copy-profile';
import CardVertical from '../../components/card-vertical/card-vertical';
import AuthorProfileSidebar from '../../UI/author-profile-sidebar/author-profile-sidebar';
import { useParams } from 'react-router-dom';
import { images } from '../../shared/constants';
import FollowAuthor from '../../components/follow-author/follow-author';
import Linkify from 'react-linkify';

const Profile = () => {
  const [shownMeatball, setShownMeatball] = useState(false);
  const [copyProfile, setCopyProfile] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const { handle } = useParams();

  const { isLoggedIn, login } = useAuthStore((state) => ({
    isLoggedIn: state.isLoggedIn,
    login: state.login,
  }));
  const user = useUserStore((state) => state.user);

  const { getAuthor, author, getCounts, counts } = useUserStore((state) => ({
    getAuthor: state.getAuthor,
    author: state.author,
    getCounts: state.getUserPostCounts,
    counts: state.userPostCounts,
  }));

  const { getUserPosts, userPosts } = usePostStore((state) => ({
    getUserPosts: state.getUserPosts,
    userPosts: state.userPosts,
  }));

  const load = () => {
    if (handle) {
      getAuthor(handle);
      getUserPosts(handle);
    }
  };

  useEffect(
    (window.onresize = window.onload =
      () => {
        setScreenWidth(window.innerWidth);
      }),
    [screenWidth]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    load();
  }, []);

  useEffect(() => {
    if (author) {
      getCounts(author.handle);
    }
  }, [author]);

  //for customizing linkify npm package
  const componentDecorator = (href: any, text: any, key: any) => (
    <a href={href} key={key} target='_blank' rel='noopener noreferrer'>
      {text}
    </a>
  );

  return (
    <div>
      <Helmet>
        <link rel='canonical' href={window.location.href} />

        {/* HTML Meta Tags */}
        <title>{`${author?.displayName} - Nuance Profile`}</title>
        <meta name='description' content={author?.bio} />

        {/* Google / Search Engine Tags */}
        <meta
          itemProp='name'
          content={`${author?.displayName} - Nuance Profile`}
        />
        <meta itemProp='description' content={author?.bio} />
        <meta
          itemProp='image'
          content={author?.avatar || images.DEFAULT_AVATAR}
        />

        {/* Facebook Meta Tags */}
        <meta property='og:url' content={window.location.href} />
        <meta property='og:type' content='website' />
        <meta
          property='og:title'
          content={`${author?.displayName} - Nuance Profile`}
        />
        <meta property='og:description' content={author?.bio} />
        <meta
          property='og:image'
          content={author?.avatar || images.DEFAULT_AVATAR}
        />

        {/* Twitter Meta Tags */}
        <meta
          name='twitter:card'
          content={author?.avatar || images.DEFAULT_AVATAR}
        />
        <meta
          name='twitter:title'
          content={`${author?.displayName} - Nuance Profile`}
        />
        <meta name='twitter:description' content={author?.bio} />
        <meta
          name='twitter:image'
          content={author?.avatar || images.DEFAULT_AVATAR}
        />
      </Helmet>

      <Header
        loggedIn={isLoggedIn}
        isArticlePage={false}
        ScreenWidth={screenWidth}
        tokens={user?.nuaTokens}
        loading={false}
      />
      <div className='profile-author-wrapper'>
        <div className='profile-sidebar'>
          <div className='wrapper'>
            {screenWidth < 768 ? (
              <div className='left-profile-menu'>
                <AuthorProfileSidebar />
              </div>
            ) : null}
            <div className='logged-out' style={{ alignItems: 'flex-end' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <CopyProfile
                  shown={copyProfile}
                  setShown={setCopyProfile}
                  handle={author?.handle}
                />
                <ReportAuthorMenu
                  shown={shownMeatball}
                  setShown={setShownMeatball}
                />
              </div>
              <div
                className='horizontal-divider'
                style={isLoggedIn ? { width: '17vw' } : {}}
              ></div>
              {/* <div
                className='buttons'
                style={isLoggedIn ? { display: 'none' } : {}}
              >
                <div className='button'>
                  <Button
                    styleType='primary-1'
                    type='button'
                    style={{ width: '265px' }}
                    // icon={NONAME}
                    onClick={() => login('ii')}
                  >
                    Log in with Internet Identity
                  </Button>
                </div>
                <div className='button'>
                  <Button
                    styleType='primary-3'
                    type='button'
                    style={{ width: '265px' }}
                    // icon={NONAME}
                    onClick={() => login('ii')}
                  >
                    Register with Internet Identity
                  </Button>
                </div>

                <a>
                  <p className='identity'>
                    <a
                      href='https://smartcontracts.org/docs/ic-identity-guide/what-is-ic-identity.html'
                      target='_blank'
                    >
                      What is internet identity?
                    </a>
                  </p>
              </div>
                </a> */}
            </div>
          </div>
        </div>

        <div className='container' style={{ width: '75%' }}>
          <div className='author-wrapper'>
            <p className='titles'>AUTHOR PROFILE</p>

            <div className='content'>
              <img
                src={author?.avatar || images.DEFAULT_AVATAR}
                alt='background'
                className='profile-picture'
              />
              <p className='name'>{author?.displayName}</p>
              <p className='username'>@{author?.handle}</p>
              <Linkify componentDecorator={componentDecorator}>
                <p className='description'>{author?.bio}</p>
              </Linkify>
            </div>

            <FollowAuthor
              AuthorHandle={author?.handle || ''}
              Followers={user?.followersArray || undefined}
              user={user?.handle || ''}
            />

            <div className='statistic'>
              <div className='stat'>
                <p className='count'>{counts?.totalPostCount || 0}</p>
                <p className='title'>Articles</p>
              </div>
              <div className='stat'>
                <p className='count'>{counts?.totalViewCount || 0}</p>
                <p className='title'>Article Views</p>
              </div>
              {/* <div className='stat'>
                <p className='count'>{counts?.uniqueClaps || 0}</p>
                <p className='title'>Claps</p>
              </div> */}
            </div>
            <div style={{ marginTop: '50px' }}>
              <p className='pub-art'>
                PUBLISHED ARTICLES ({counts?.publishedCount || 0})
              </p>

              {screenWidth > 768 ? (
                <div
                  className='article-grid-horizontal'
                  style={{ margin: '50px -42px 50px 0' }}
                >
                  {(userPosts || []).map((post: any) => (
                    <CardHorizontal post={post} key={post.postId} />
                  ))}
                  <Footer />
                </div>
              ) : (
                <div className='article-grid' style={{ margin: '0 0 0 -8px' }}>
                  {(userPosts || []).map((post: any) => (
                    <CardVertical post={post} key={post.postId} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
