const imagesPath = '/assets/images/';
const iconsPath = imagesPath + 'icons/';
const loadersPath = imagesPath + 'loaders/';

export const images = {
  IC_BADGE: imagesPath + 'ic-badge.png',
  AIKIN_LOGO: imagesPath + 'aikin_logo.png',
  AIKIN_LOGO_HORIZONTAL: imagesPath + 'aikin_logo_horiz.png',
  NUANCE_LOGO: imagesPath + 'FastBlocksLogo.png',
  NUANCE_LOGO_BLUE_TEXT: imagesPath + 'nuance_logo_blue_text.png',
  DEFAULT_AVATAR: imagesPath + 'default-avatar.jpeg',
  NUANCE_FLAG: imagesPath + 'nuance-flag.png',
  PHOTO_BACKGROUND: imagesPath + 'photo-bg.png',
  PHOTO_CAMERA: imagesPath + 'photo-camera.png',

  loaders: {
    NUANCE_LOADER: loadersPath + 'nuance-loader.gif',
    BUTTON_SPINNER: loadersPath + 'button-spinner.gif',
  },
};

export const icons = {
  EDIT: iconsPath + 'edit.svg',
  SEARCH_WHITE: iconsPath + 'search-white.svg',
  SEARCH_BLACK: iconsPath + 'search-black.svg',
  CLOSE_SEARCH: iconsPath + 'close-search.svg',
  NOTIFICATION: iconsPath + 'notification.svg',
  TAG: iconsPath + 'tag.svg',
  UPLOAD_PICTURE: iconsPath + 'upload-picture.svg',
  EMAIL_OPT_IN: iconsPath + 'email-opt-in.png',
  EMAIL_OPT_IN_SUCCESS: iconsPath + 'email-opt-in-success.png',
  CHANGE_PHOTO: iconsPath + 'change-photo.svg',
  THREE_DOTS: iconsPath + 'three-dots.svg',
  THREE_DOTS_BLUE: iconsPath + 'three-dots-blue.png',
  THREE_DOTS_WHITE: iconsPath + 'three-dots-white.svg',
  COPY: iconsPath + 'copy.svg',
  COPY_BLUE: iconsPath + 'copy-blue.svg',
  USER: iconsPath + 'user.svg',
  USER_BLUE: iconsPath + 'user-blue.svg',
  USER_HOVER: iconsPath + 'user-hover.png',
  USER_DOWN: iconsPath + 'user-down.png',
  USER_WHITE: iconsPath + 'user-white.svg',
  USER_WHITE_MOBILE: iconsPath + 'user-white-mobile.svg',
  CLAP_WHITE: iconsPath + 'clap-white.svg',
  CLAP_BLUE: iconsPath + 'clap-blue.png',

  editor: {
    BREAK: 'break.svg',
    BOLD: 'bold.svg',
    ITALIC: 'italic.svg',
    LINK: 'link.svg',
    LIST: 'list.svg',
    LIST_NUMBERS: 'list-numbers.svg',
    PHOTO: 'photo.svg',
    QUOTES: 'quotes.svg',
    SIZE: 'size.svg',
  },
};

export const colors = {
  primaryColor: '#500e50',
  primaryTextColor: 'black',
  subtitleColor: '#687487',
  accentColor: '#8e72e0',
  highlightTwo: '#500e50',
  primaryBackgroundColor: '#ffffff',
  tagTokenBackGround: '#f2f2f2',
  tagTextColor: '#666666',
  editProfileInputTextColor: '#19192E',
  darkerBorderColor: '#999999',
  emailOptInButtonColor: '#722680',
};
