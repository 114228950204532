// original source: https://github.com/peterKaleta/react-token-autocomplete

import React from 'react';

type OptionProps = {
  value: string;
  isSelected: boolean;
  index: number;
  handleSelect: (index: number) => void;
  handleClick: (index: number) => void;
};

const Option: React.FC<OptionProps> = ({
  value,
  isSelected,
  index,
  handleSelect: handleSelect,
  handleClick,
}) => {
  const onMouseEnter = () => {
    handleSelect(index);
  };

  const onClick = () => {
    handleClick(index);
  };

  return (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      className={`tags-token-autocomplete${isSelected ? ' selected' : ''}`}
    >
      {value}
    </div>
  );
};

export default Option;
