import { format } from 'date-fns';

export enum DateFormat {
  // Sep 16
  NoYear,
  // Sep 16 2021
  WithYear,
  // Sep 16 - 2021
  WithYearAndHyphen,
}

export const formatDate = (
  epochTime: string | undefined,
  dateFormat: DateFormat = DateFormat.WithYear
): string => {
  // Supports nullish values to keep UI templates clean from null checks
  if (!epochTime?.trim().length) {
    return '';
  }
  const ms = Number.parseInt(epochTime.trim());
  if (ms === NaN || ms === 0) {
    return '';
  } else {
    switch (dateFormat) {
      case DateFormat.WithYear:
        return format(new Date(ms), 'MMM d yyyy');
      case DateFormat.WithYearAndHyphen:
        return format(new Date(ms), 'MMM d - yyyy');
      default:
        // NoYear
        return format(new Date(ms), 'MMM d');
    }
  }
};

export const arraysEqual = <T>(a: T[], b: T[]) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const base64toBlob = (
  base64Data: string,
  contentType = '',
  sliceSize = 512
) => {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    byteArrays.push(new Uint8Array(byteNumbers));
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export type EmbeddedImage = {
  index: number;
  length: number;
  mimeType: string;
  blob: Blob;
  contentId?: string;
};

export const getEmbeddedImages = (html: string): EmbeddedImage[] => {
  const images: EmbeddedImage[] = [];

  const matches = [
    ...html.matchAll(
      // The regex must include the src attribute's quotes in the regex group.
      // This allows a replace with additional element attributes (class, style, etc.).
      /<img[^>]*src=(?<data>["']data:[^"']*;base64,[^"']*["'])[^>]*>/gi
    ),
  ];

  for (const match of matches) {
    if (match.groups?.data) {
      const data = match.groups.data;
      const parsed = parseEmbeddedImage(
        data,
        (match.index || 0) + match[0].indexOf(data)
      );

      if (parsed) {
        images.push(parsed);
      }
    }
  }

  return images;
};

export const parseEmbeddedImage = (
  embeddedImage: string,
  index: number = 0
): EmbeddedImage | null => {
  // "data:image/png;base64,<base64>"
  var parts = embeddedImage.replaceAll(/["']/g, '').split(/[:;,]/);

  if (parts.length === 4) {
    let mimeType = parts[1];
    let base64 = parts[3];

    //pad end of base64 string with = characters
    if (base64.length % 4 > 0) {
      base64 += '='.repeat(4 - (base64.length % 4));
    }

    const blob = base64toBlob(base64, mimeType);

    return {
      index,
      length: embeddedImage.length,
      mimeType,
      blob,
    };
  } else {
    return null;
  }
};
