import React from 'react';
import { icons } from '../../shared/constants';

type UnpublishArticleProps = {
  shown: boolean;
  setShown: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDraft: React.Dispatch<React.SetStateAction<boolean>>;
  savePost: () => Promise<void>;
};

const UnpublishArticle: React.FC<UnpublishArticleProps> = (
  props
): JSX.Element => {
  const CloseMenu = () => {
    props.setShown(false);
  };

  const unpublishArticleHandler = () => {
    props.savePost();
  };

  return (
    <div className='meatball-menu'>
      <img
        onClick={() => {
          props.setShown(!props.shown);
        }}
        src={props.shown ? icons.THREE_DOTS_BLUE : icons.THREE_DOTS}
        alt='meatball-menu'
        style={{ cursor: 'pointer' }}
      />

      <div
        className='drop-down-content'
        onMouseLeave={CloseMenu}
        style={
          props.shown
            ? {
                height: 78,
                boxShadow: '0px 2px 10px 5px rgba(117, 117, 117, 0.08)',
              }
            : { height: 0 }
        }
      >
        <ul style={props.shown ? {} : { display: 'none' }}>
          <a onClick={unpublishArticleHandler}>
            <li>Unpublish Article</li>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default UnpublishArticle;
