export const idlFactory = ({ IDL }) => {
  const Result = IDL.Variant({ 'ok' : IDL.Null, 'err' : IDL.Text });
  const Result_1 = IDL.Variant({ 'ok' : IDL.Vec(IDL.Text), 'err' : IDL.Text });
  return IDL.Service({
    'createEmailOptInAddress' : IDL.Func([IDL.Text], [Result], []),
    'dumpOptInEmailAddress' : IDL.Func([], [IDL.Text], ['query']),
    'getAdmins' : IDL.Func([], [Result_1], ['query']),
    'registerAdmin' : IDL.Func([IDL.Text], [Result], []),
    'unregisterAdmin' : IDL.Func([IDL.Text], [Result], []),
  });
};
export const init = ({ IDL }) => { return []; };
