import React from 'react';

type InputProps = {
  defaultText: string;
  width: string;
  height: string;
  fontSize: string;
  fontFamily: string;
  fontColor: string;
  value?: string;
  maxLength?: number;
  onChange?: (postTitle: string) => void;
  hasError: boolean;
};

const InputField: React.FC<InputProps> = (props): JSX.Element => {
  const className = props.hasError ? 'has-error textarea' : 'textarea';
  return (
    <div className='input-attributes2'>
      <input
        className={className}
        role='input'
        placeholder={String(props.defaultText)}
        style={{
          width: String(props.width),
          fontSize: String(props.fontSize),
          fontFamily: String(props.fontFamily),
          color: String(props.fontColor),
        }}
        contentEditable
        maxLength={props.maxLength}
        value={props.value}
        onChange={(e) => props.onChange && props.onChange(e.target.value)}
      />
    </div>
  );
};

export default InputField;
