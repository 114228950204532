import React, { useState, useEffect } from 'react';
import Button from '../../UI/Button/Button';
import { useUserStore } from '../../store';
import { images } from '../../shared/constants';

type FollowAuthorProps = {
  AuthorHandle: string;
  Followers: Array<String> | undefined;
  user: string;
};

const FollowAuthor: React.FC<FollowAuthorProps> = (props): JSX.Element => {
  const [following, setFollowing] = useState(false);
  const [loading, setLoading] = useState(false);

  const { followAuthor, unfollowAuthor } = useUserStore((state) => ({
    followAuthor: state.followAuthor,
    unfollowAuthor: state.unfollowAuthor,
  }));

  function handleFollow() {
    if (!props.Followers || props?.Followers.includes(props.AuthorHandle)) {
      return;
    }

    if (props.AuthorHandle) {
      followAuthor(props.AuthorHandle);
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 10000);
    }
  }
  function handleUnfollow() {
    if (props.AuthorHandle) {
      unfollowAuthor(props.AuthorHandle);
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 10000);
    }
  }

  function handleRegister() {
    window.location.href = `/register`;
  }

  useEffect(() => {
    if (props.Followers && props.AuthorHandle) {
      setFollowing(props.Followers.includes(props.AuthorHandle));
      setLoading(false);
    }
  }, [props.Followers, props.AuthorHandle]);
  return (
    <div className='followAuthor'>
      {/* {following ? (
        <Button
          styleType='secondary'
          type='button'
          style={{ width: '110px', margin: '10px 0' }}
          onClick={handleUnfollow}
          disabled={loading}
          icon={loading ? images.loaders.BUTTON_SPINNER : ''}
        >
          Following
        </Button>
      ) : (
        <Button
          styleType='secondary'
          type='button'
          style={{ width: '96px', margin: '10px 0' }}
          onClick={props.user ? handleFollow : handleRegister}
          disabled={loading}
          icon={loading ? images.loaders.BUTTON_SPINNER : ''}
        >
          Follow
        </Button>
      )} */}
    </div>
  );
};

export default FollowAuthor;
