import React, { useEffect } from 'react';
import { usePostStore, useUserStore, useAuthStore } from '../../store';
import CardDraftArticles from '../../components/card-draft-articles/card-draft-articles';
import { PostType } from '../../types/types';
import { useNavigate } from 'react-router-dom';

const DraftArticles = () => {
  const { user, getUser, getCounts, counts } = useUserStore((state) => ({
    user: state.user,
    getUser: state.getUser,
    getCounts: state.getUserPostCounts,
    counts: state.userPostCounts,
  }));

  const { getMyDraftPosts, myDraftPosts } = usePostStore((state) => ({
    getMyDraftPosts: state.getMyDraftPosts,
    myDraftPosts: state.myDraftPosts,
  }));

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    getUser();
    getMyDraftPosts(0, 1000);
  }, []);

  useEffect(() => {
    if (user) {
      getCounts(user.handle);
    }
  }, [user]);

  useEffect(() => {
    if (isLoggedIn && !user) {
      navigate('/', { replace: true });
    }
  }, [isLoggedIn, user]);

  return (
    <div className='wrapper' style={{ width: '100%' }}>
      <p className='title'>DRAFT ARTICLES ({counts?.draftCount || 0})</p>
      <div className='article-grid-horizontal'>
        {(myDraftPosts || []).map((post: PostType) => (
          <CardDraftArticles post={post} key={post.postId} />
        ))}
      </div>
    </div>
  );
};

export default DraftArticles;
