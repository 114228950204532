import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuthStore } from '../../store';
import { colors, icons } from '../../shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

type ProfileMenuProps = {
  shown: boolean;
  isArticle: Boolean;
  screenWidth: Number;
  setShown: React.Dispatch<React.SetStateAction<boolean>>;
  setShownMeatball: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileMenu: React.FC<ProfileMenuProps> = (props): JSX.Element => {
  const logout = useAuthStore((state) => state.logout);
  const ref = React.createRef<HTMLDivElement>();

  const CloseMenu = () => {
    props.setShown(false);
  };
  // const [user, setUser] = useState<UserType | null | undefined>(null);
  const [ProfilePic, setProfilePic] = useState(icons.USER);
  const [hover, setHover] = useState(false);

  const MouseOver = () => {
    setProfilePic(icons.USER_HOVER);
  };
  const MouseDown = () => {
    setProfilePic(icons.USER_DOWN);
  };
  const MouseLeave = () => {
    setProfilePic(icons.USER);
  };

  const onLogOut = () => {
    logout();
    window.location.href = '/';
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (props.shown && ref.current && !ref.current.contains(e.target)) {
        CloseMenu();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);
    document.addEventListener('keydown', (e) => {
      if (props.shown && e.key == 'Escape') {
        CloseMenu();
      }
    });

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [props.shown]);

  // useEffect(() => {
  //   // setUser(getUser());
  // }, []);
  return (
    <div className='meatball-menu profileMenuIcon' ref={ref}>
      <FontAwesomeIcon
        onClick={() => {
          props.setShown(!props.shown);
          props.setShownMeatball(false);
        }}
        icon={faUser}
        style={hover ? {color: colors.accentColor, cursor: "pointer"} : {color: colors.primaryColor}}
        // src={
        //   props.shown
        //     ? icons.USER_BLUE
        //     : props.isArticle
        //     ? props.screenWidth < 768
        //       ? icons.USER_WHITE_MOBILE
        //       : icons.USER_WHITE
        //     : ProfilePic
        // }
        // alt=''
        // onMouseOver={MouseOver}
        // onMouseDown={MouseDown}
        // onMouseLeave={MouseLeave}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />

      <div
        className='drop-down-content-menu'
        // onMouseLeave={CloseMenu}
        style={
          props.shown
            ? {
                height: 235,
                boxShadow: '0px 2px 10px 5px rgba(117, 117, 117, 0.08)',
              }
            : { height: 0 }
        }
      >
        <ul
          className='alignment'
          style={props.shown ? {} : { display: 'none' }}
        >
          <Link to='/my-profile'>
            <li>My profile</li>
          </Link>
          <Link to='/my-profile/draft'>
            <li>My draft articles</li>
          </Link>
          <Link to='/my-profile/published'>
            <li>My published articles</li>
          </Link>
          <Link to='/article/new'>
            <li>Create an article</li>
          </Link>
          <div className='horizontal-divider'></div>
          <a>
            <span onClick={onLogOut}>
              <li>Log out</li>
            </span>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default ProfileMenu;
