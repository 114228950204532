import React, {useState} from 'react';
import { colors, icons } from '../../shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

type UnpublishArticleProps = {
  shown: boolean;
  setShown: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReportAuthorMenu: React.FC<UnpublishArticleProps> = (
  props
): JSX.Element => {
  const CloseMenu = () => {
    props.setShown(false);
  };

  const ReportAuthorHandler = () => {
    console.log('... Report author logic.');
  };

  const [hover, setHover] = useState(false);

  return (
    <div className='meatball-menu'>
      <FontAwesomeIcon
        icon={faEllipsis}
        onClick={() => {
          props.setShown(!props.shown);
        }}
        // src={props.shown ? icons.THREE_DOTS_BLUE : icons.THREE_DOTS}
        // alt='meatball-menu'
        style={hover ? { cursor: 'pointer', color: colors.accentColor } : {color: colors.primaryColor}}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />

      <div
        className='drop-down-content'
        onMouseLeave={CloseMenu}
        style={
          props.shown
            ? {
                height: 78,
                boxShadow: '0px 2px 10px 5px rgba(117, 117, 117, 0.08)',
              }
            : { height: 0 }
        }
      >
        <ul style={props.shown ? {} : { display: 'none' }}>
          <a onClick={ReportAuthorHandler}>
            <li>Report Author</li>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default ReportAuthorMenu;
