import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CardVerticalProps } from '../card-vertical/types';
import { DateFormat, formatDate } from '../../shared/utils';
import { images } from '../../shared/constants';

const CardHorizontal: React.FC<CardVerticalProps> = ({ post }) => {
  const navigate = useNavigate();

  return (
    <div className='card-wrapper-horizontal'>
      <img
        className='main-pic-horizontal'
        src={post.headerImage || images.NUANCE_LOGO}
        alt='Article header image'
        onClick={() => navigate(post.url)}
      />
      <div className='horizontal'>
        <div className='publisher'>
          <div className='card-creator-horizontal'>
            <div className='left-card-icons'>
              <img
                className='profile-pic'
                src={post.avatar || images.DEFAULT_AVATAR}
                alt='Author image'
              />
              <div>
                <Link to={`/${post.handle}`}>@{post.handle}</Link>
              </div>
            </div>

            <div className='published-date'>
              <span>|</span>
              <p>{formatDate(post.modified, DateFormat.NoYear)}</p>
            </div>
          </div>
        </div>
        <div className='article-text'>
          <Link to={post.url}>
            <h2>{post.title}</h2>
          </Link>
          <p>{post.subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default CardHorizontal;
