import React, { useState } from 'react';
import { icons, colors } from '../../shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

type CopyArticleProps = {
  url: string;
  shown: boolean;
  setShown: React.Dispatch<React.SetStateAction<boolean>>;
};

const CopyArticle: React.FC<CopyArticleProps> = (props): JSX.Element => {
  const CloseMenu = () => {
    props.setShown(false);
  };

  const copyLinkToArticle = () => {
    console.log(window.location.origin);
    // This ensures that the links work locally, in UAT and PROD; When in PROD they display NAUNCE.XYZ
    if (
      window.location.origin == 'https://exwqn-uaaaa-aaaaf-qaeaa-cai.ic0.app'
    ) {
      navigator.clipboard.writeText('http://www.nuance.xyz' + props.url);
    } else {
      navigator.clipboard.writeText(window.location.origin + props.url);
    }
    setTimeout(() => {
      CloseMenu();
    }, 2000);
  };

  const [hover, setHover] = useState(false);

  return (
    <div className='meatball-menu'>
      <FontAwesomeIcon
        onClick={() => {
          props.setShown(!props.shown), copyLinkToArticle();
        }}
        icon={faLink}
        // src={props.shown ? icons.COPY_BLUE : icons.COPY}
        // alt='copy-article-menu'
        style={hover ? { cursor: 'pointer', color: colors.accentColor } : {color: colors.primaryColor}}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />

      <div
        className='drop-down-content'
        style={
          props.shown
            ? {
                height: 82,
                boxShadow: '0px 2px 10px 5px rgba(117, 117, 117, 0.08)',
              }
            : { height: 0 }
        }
      >
        <ul style={props.shown ? {} : { display: 'none' }}>
          <div>
            <a>
              <li style={{ cursor: 'hand' }}>Link copied!</li>
            </a>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default CopyArticle;
