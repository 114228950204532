import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import MeatBallMenu from '../../UI/meatball-menu/meatball-menu';
import ProfileMenu from '../../UI/profile-menu/profile-menu';
import SearchModal from '../search-modal/search-modal';
import { colors, icons, images } from '../../shared/constants';
import { usePostStore, useUserStore } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

type HeaderProps = {
  loggedIn: Boolean;
  isArticlePage: Boolean;
  ScreenWidth: Number;
  isReadArticlePage?: Boolean;
  isMyProfilePage?: Boolean;
  tokens?: Number;
  loading: Boolean;
};

const Header: React.FC<HeaderProps> = (props): JSX.Element => {
  const [shownMeatball, setShownMeatball] = useState(false);
  const [shownProfile, setShownProfile] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const { clearSearchBar, isTagScreen } = usePostStore((state) => ({
    clearSearchBar: state.clearSearchBar,
    isTagScreen: state.isTagScreen,
  }));

  const clearSearch = () => {
    if (window.location.pathname === '/') {
      clearSearchBar(true);
      window.location.reload();
    }
  };

  const { user } = useUserStore((state) => ({
    user: state.user,
  }));

  return (
    <div
      className='header-wrapper'
      style={
        props.isArticlePage
          ? { backgroundColor: colors.primaryBackgroundColor }
          : {}
      }
    >
      {modalOpen && (
        <SearchModal
          setOpenModal={setModalOpen}
          screenWidth={props.ScreenWidth}
        />
      )}
      {props.loggedIn == false && props.ScreenWidth < 768 ? (
        <Link to='/'>
          <img
            className='icon'
            onClick={clearSearch}
            src={images.NUANCE_LOGO}
            alt=''
          />
        </Link>
      ) : (
        <Link to='/'>
          <img
            className='icon'
            onClick={clearSearch}
            src={images.NUANCE_LOGO}
            alt=''
          />
        </Link>
      )}
      <div className='right-icons'>
        {props.isArticlePage && props.ScreenWidth < 768 ? (
          <>
            <img
              className='header-icon1'
              onClick={() => {
                setModalOpen(true);
              }}
              src={icons.SEARCH_WHITE}
            />{' '}
            {
              props.isMyProfilePage ? '' : '' //<img className='header-icon2' src={icons.NOTIFICATION} />
            }
          </>
        ) : (
          ''
        )}

        {props.isReadArticlePage ? (
          <FontAwesomeIcon
            icon={faSearch}
            style={
              hover
                ? {
                    color: colors.accentColor,
                    cursor: 'pointer',
                    marginRight: '10px',
                  }
                : { color: colors.primaryColor, marginRight: '10px' }
            }
            // src={icons.SEARCH_BLACK}
            onClick={() => {
              setModalOpen(true);
            }}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          />
        ) : (
          ''
        )}

        {/* <div className={props.loggedIn ? 'token-display' : 'hide-icon'}>
          <div>
            <img
              src={
                props.loading
                  ? images.loaders.NUANCE_LOADER
                  : images.NUANCE_LOGO
              }
              style={{ width: '20px', height: '20px', marginTop: '0px' }}
            ></img>
          </div>
          <span
            className={
              props.isMyProfilePage || props.isArticlePage ? 'header-span' : ''
            }
          >
            {props.tokens}
          </span>
        </div> */}

        {props.loggedIn && user ? (
          <ProfileMenu
            shown={shownProfile}
            isArticle={props.isArticlePage}
            setShown={setShownProfile}
            setShownMeatball={setShownMeatball}
            screenWidth={props.ScreenWidth}
          />
        ) : (
          ''
        )}

        {/* <MeatBallMenu
          shown={shownMeatball}
          isArticle={props.isArticlePage}
          setShown={setShownMeatball}
          setShownProfile={setShownProfile}
        /> */}
      </div>
    </div>
  );
};

export default Header;
