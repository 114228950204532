import React, { useState } from 'react';
import { base64toBlob } from '../../shared/utils';
import { useUserStore } from '../../store';
import { colors, images } from '../../shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

// // stavi na input type = file

type ProfilePictureProps = {
  avatar: string;
  onChange?: (
    imageUrl: string,
    value: Blob,
    mimeType: string,
    fileSize: number,
    e: any
  ) => void;
};

const ProfilePictureButton: React.FC<ProfilePictureProps> = (
  props: ProfilePictureProps
) => {
  const user = useUserStore((state) => state.user);
  const [clicked, setClicked] = useState(false);
  const [test, setTest] = useState('');

  const ShrinkCameraIcon = () => {
    setClicked(true);
  };
  const EnlargeCamerIcon = () => {
    setClicked(false);
  };

  const UploadPicture = (e: any) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.readAsDataURL(file);
    }
    reader.onloadend = () => {
      if (reader.result === null) {
        throw new Error('file empty...');
      }
      const data = typeof reader.result == 'string' ? reader.result : null;
      setTest(data as string);
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      const blob = base64toBlob(encoded, file.type);
      props.onChange &&
        props.onChange(data as string, blob, file.type, file.size, e);
    };
  };

  return (
    <div
      onClick={ShrinkCameraIcon}
      onMouseOut={EnlargeCamerIcon}
      className='profile-picture'
    >
      <input
        id='file'
        type='file'
        style={{ display: 'none' }}
        required
        onChange={UploadPicture}
      />
      <label htmlFor='file' className='profile-picture'>
        {/* {test ? (
          <img
            className='uploaded-pic'
            src={props.avatar}
            alt='background'
            onChange={UploadPicture}
          />
        ) : (
          <img src={images.PHOTO_BACKGROUND} alt='background' />
        )} */}
        <FontAwesomeIcon
          className={clicked ? 'pic-camera-clicked' : 'pic-camera'}
          icon={faCamera}
          style={{color: colors.accentColor, marginRight: "5px", fontSize: "5em"}}
          // src={user?.avatar || images.PHOTO_CAMERA}
          // alt='no-profile'
        />
      </label>
    </div>
  );
};

export default ProfilePictureButton;
