import React, { useEffect, useState } from 'react';
import { useUserStore } from '../../store';
import { PostType } from '../../types/types';
import CardHorizontal from '../card-horizontal/card-horizontal';
import SearchBar from '../search-bar/search-bar';
import { icons } from '../../shared/constants';
import { usePostStore } from '../../store';
import { useNavigate, Link } from 'react-router-dom';

function SearchModal({ setOpenModal, screenWidth }: any) {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  const [posts, setPosts] = useState<PostType[] | null | undefined>([]);
  const [filterData, setFilterData] = useState<string>('');

  const { clearSearchBar, isTagScreen } = usePostStore((state) => ({
    clearSearchBar: state.clearSearchBar,
    isTagScreen: state.isTagScreen,
  }));
  const setSearchText = usePostStore((state) => state.setSearchText);

  useEffect(() => {
    let posts: PostType[] = []; //getLatestPosts();
    setPosts(posts);
  }, []);

  const filteredData = () => {
    return posts?.filter((post) => {
      return (
        post.handle.includes(filterData) ||
        post.title.includes(filterData) ||
        post.subtitle.includes(filterData) ||
        post.tags.some((tag) => tag.tagName.includes(filterData))
      );
    });
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      console.log(e.target.value);
      setFilterData(e.target.value);
      setSearchText(e.target.value);
      clearSearchBar(false);
      navigate('/', { replace: true });
    }
  };

  return (
    <div
      className='modal-wrapper'
      style={screenWidth < 768 ? { top: '50px' } : { top: '64px' }}
    >
      <div className='content'>
        <div className='close-modal'>
          <img
            src={icons.CLOSE_SEARCH}
            onClick={() => {
              setOpenModal(false);
            }}
          ></img>
        </div>

        <p>SEARCH</p>
        <SearchBar onKeyDown={onKeyDown}></SearchBar>

        <div className='posts'>
          {(() => {
            if (filteredData()?.length != 0 && filterData != '') {
              let number = filteredData()?.length;
              return (
                <div style={{ marginLeft: '60px' }}>
                  {(() => {
                    if (number == 1) {
                      return (
                        <div className='rowContainer'>
                          <h3 className='span2'>|</h3>
                          <h3 className='links'>
                            Found {number} article for{' '}
                            <span className='result'>'{filterData}'</span>
                          </h3>
                        </div>
                      );
                    } else {
                      return (
                        <div className='rowContainer'>
                          <h3 className='span2'>|</h3>
                          <h3 className='links'>
                            Found {number} articles for{' '}
                            <span className='result'>'{filterData}'</span>
                          </h3>
                        </div>
                      );
                    }
                  })()}
                  <div className='article-grid-horizontal'>
                    {filteredData()?.map((post: PostType) => (
                      <CardHorizontal post={post} key={post.postId} />
                    ))}
                  </div>
                </div>
              );
            } else {
              return <div className='latestArticles'></div>;
            }
          })()}
        </div>
      </div>
    </div>
  );
}

export default SearchModal;
