import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuthStore, useUserStore, usePostStore } from '../../store';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import SearchBar from '../../components/search-bar/search-bar';
import CardVertical from '../../components/card-vertical/card-vertical';
import CardHorizontal from '../../components/card-horizontal/card-horizontal';
import { PostType } from '../../types/types';
import MeatBallSidebar from '../../UI/meatball-sidebar/meatball-sidebar';
import InputField2 from '../../UI/InputField2/InputField2';
import Button from '../../UI/Button/Button';
import { validate } from 'email-validator';
import LoggedOutSidebar from '../../components/logged-out-sidebar/logged-out-sidebar';
import Loader from '../../UI/loader/Loader';
import { colors, images, icons } from '../../shared/constants';
import { TagModel } from 'src/nuance_assets/services/actorService';
import { SpinnerCircular } from 'spinners-react';
import RequiredFieldMessage from '../../components/required-field-message/required-field-message';
import API from '../../shared/API';
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';

const HomePageGrid = () => {
  const navigate = useNavigate();

  const searchPageSize = 20;

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const refEmailOptInMobile = useRef<HTMLDivElement>(null);

  const { user, getUser, unregistered, getCounts, counts } = useUserStore(
    (state) => ({
      user: state.user,
      getUser: state.getUser,
      unregistered: state.unregistered,
      getCounts: state.getUserPostCounts,
      counts: state.userPostCounts,
    })
  );

  const {
    getLatestPosts,
    getMoreLatestPosts,
    latestPosts,
    moreLatestPosts,
    setSearchText,
    search,
    clearSearch,
    searchText,
    searchResults,
    searchTotalCount,
    followTag,
    unfollowTag,
    getAllTags,
    getMyTags,
    allTags,
    myTags,
    clearSearchBar,
    isTagScreen,
  } = usePostStore((state) => ({
    clearSearchBar: state.clearSearchBar,
    isTagScreen: state.isTagScreen,
    getLatestPosts: state.getLatestPosts,
    latestPosts: state.latestPosts,
    getMoreLatestPosts: state.getMoreLatestPosts,
    moreLatestPosts: state.moreLatestPosts,

    setSearchText: state.setSearchText,
    search: state.search,
    clearSearch: state.clearSearch,
    searchText: state.searchText,
    searchResults: state.searchResults,
    searchTotalCount: state.searchTotalCount,

    followTag: state.followTag,
    unfollowTag: state.unfollowTag,
    getAllTags: state.getAllTags,
    getMyTags: state.getMyTags,
    myTags: state.myTags,
    allTags: state.allTags,
  }));

  const { createEmailOptInAddress } = useUserStore((state) => ({
    createEmailOptInAddress: state.createEmailOptInAddress,
  }));

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastSearchPhrase, setLastSearchPhrase] = useState('');
  const [screenWidth, setScreenWidth] = useState(0);
  const [isBlur, setIsBlur] = useState(false);

  const [indexFrom, setIndexFrom] = useState(0);
  const [indexTo, setIndexTo] = useState(searchPageSize - 1);
  const [loadedSearchResults, setLoadedSearchResults] = useState<PostType[]>(
    []
  );

  const [searchedTag, setSearchedTag] = useState<TagModel>();
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [isFollowingTag, setIsFollowingTag] = useState(false);
  const [updatingFollow, setUpdatingFollow] = useState(false);
  const [firstSave, setFirstSave] = useState(false);
  const [emailAddressWarning, setemailAddressWarning] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [emailOptInSuccess, setEmailOptInSuccess] = useState(false);
  const MAILERLITE_API_KEY = process.env.MAILERLITE_API_KEY;

  const onEmailAddressChange = (value: string) => {
    setEmailAddress(value);
  };

  const onEmailOptIn = async () => {
    setFirstSave(true);
    const isValid = validateEmail(emailAddress);
    if (!isValid) {
      return;
    }

    window.scrollTo(0, 0);
    setLoading(true);
    setEmailOptInSuccess(false);

    setTimeout(() => {
      setLoading(false);
    }, 7000);

    try {
      await createEmailOptInAddress(emailAddress as string);
      setEmailOptInSuccess(true);
      API.Post(
        `https://connect.mailerlite.com/api/subscribers/`,
        {
          email: emailAddress as string,
        },
        MAILERLITE_API_KEY
      ).then((res) => console.log(res));
    } catch (err) {
      setEmailOptInSuccess(false);
      throw new Error(
        'There was an error processing your sign-up request...Please try again later!!!'
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUser();
    getAllTags();
    getMyTags();
    if (searchText) {
      handleSearch();
    } else {
      getLatestPosts(0, 31);
      setPage(1);
    }
  }, []);

  useEffect(() => {
    if (user) {
      getCounts(user.handle);
    }
  }, [user]);

  useEffect(() => {
    if (searchText.trim().length == 0) {
      clearSearch();
    }
  }, [searchText]);

  useEffect(() => {
    setemailAddressWarning(firstSave && emailAddress === '');
  }, [emailAddress, firstSave]);

  useEffect(() => {
    setemailAddressWarning(firstSave && !validate(emailAddress));
  }, [emailAddress, firstSave]);

  useEffect(() => {
    if (searchedTag) {
      const found = myTags?.find((t) => t.tagId === searchedTag?.id);
      setIsFollowingTag(!!found);
    } else {
      setIsFollowingTag(false);
    }
  }, [searchedTag, myTags]);

  useEffect(() => {
    setUpdatingFollow(false);
  }, [myTags]);

  useEffect(() => {
    if (searchResults?.length) {
      let updatedResults: PostType[] = [];

      if (indexFrom > 0) {
        const uniqueSearchResults = (searchResults || []).filter(
          (r) => !loadedSearchResults.find((lr) => lr.postId === r.postId)
        );
        updatedResults = [...loadedSearchResults, ...uniqueSearchResults];
      } else {
        updatedResults = searchResults;
      }

      setLoadedSearchResults(updatedResults);
      setIndexFrom(updatedResults.length);
      setIndexTo(updatedResults.length + searchPageSize - 1);
    }

    if (searchResults !== undefined) {
      setLoading(false);
      setLoadingMore(false);
    }
  }, [searchResults]);

  useEffect(() => {
    // If the user tried to login with a child
    // component, and registerUser returned an error,
    // the user needs to register first.
    if (isLoggedIn && !user && unregistered) {
      navigate('/', { replace: true });
    }
  }, [isLoggedIn, user, unregistered]);

  useEffect(
    (window.onresize = window.onload =
      () => {
        setScreenWidth(window.innerWidth);
      }),
    [screenWidth]
  );

  useEffect(() => {
    if (
      location.pathname.split('/').pop() == 'subscription' &&
      window.innerWidth < 768
    ) {
      refEmailOptInMobile.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [screenWidth]);

  const resetPageIndexes = () => {
    setIndexFrom(0);
    setIndexTo(searchPageSize - 1);
  };

  const searchTextToTag = (s: string) => {
    const tagNames = [...s.matchAll(/#[^#]+/gm)].map((x) =>
      x[0].trim().replace(/ +/g, ' ')
    );
    let validTagNames: TagModel[] = [];
    for (const tagName of tagNames) {
      if (tagName.startsWith('#') && tagName.length > 1) {
        const found = allTags?.find(
          (t) => t.value.toUpperCase() === tagName.substring(1).toUpperCase()
        );
        if (found) {
          validTagNames.push(found);
        }
      }
    }
    return validTagNames;
  };

  const handleSearch = async () => {
    let phrase = searchText.trim();
    if (phrase) {
      setLoading(true);

      setLoadedSearchResults([]);
      resetPageIndexes();

      // If search starts with #, it's a tag search like #sports
      const tags = searchTextToTag(phrase);
      let isTagSearch = tags.length > 0;
      if (isTagSearch) {
        setSearchedTag(tags[0]);
        phrase = tags[0].value;
      } else {
        setSearchedTag(undefined);
      }

      resetPageIndexes();
      search(phrase, isTagSearch, 0, searchPageSize - 1);

      setLastSearchPhrase(phrase); //not bound to input
      setShowSearchResults(true);
    } else {
      setShowSearchResults(false);
      setSearchedTag(undefined);
      clearSearch();
    }
  };

  const handleClickMore = () => {
    // prevent clicks while button spinner is visible
    if (loadingMore) {
      return;
    }

    setLoadingMore(true);
    search(lastSearchPhrase, !!searchedTag, indexFrom, indexTo);
  };

  // Here the API will be called for search, and that is defined in the yaml.
  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setIsBlur(false);
      handleSearch();
    }
  };

  //handling click on latest articles
  const handleLatestArticlesClick = () => {
    setLoadedSearchResults([]);
    setShowSearchResults(false);
    resetPageIndexes();
    clearSearch();
    setSearchText('');
    setIsBlur(false);
  };

  const handleFollowClicked = () => {
    // prevent clicks while button spinner is visible
    if (updatingFollow) {
      return;
    }

    if (searchedTag) {
      setUpdatingFollow(true);
      if (isFollowingTag) {
        unfollowTag(searchedTag.id);
      } else {
        followTag(searchedTag.id);
      }
    }

    // remove button spinner after 5 seconds
    // in case the canister returns an error
    setTimeout(() => {
      setUpdatingFollow(false);
    }, 10000);
  };

  //For the mobile version, we display the email Opt in panel as the 4th article.
  //Hence, the arrays need to be sliced differently in the mobile and desktop versions of the application

  let slice1 = latestPosts?.slice(0, 3);
  let slice2 = latestPosts?.slice(3, 9);
  let slice3 = latestPosts?.slice(9, 19);
  if (window.innerWidth > 768) {
    slice1 = latestPosts?.slice(0, 4);
    slice2 = latestPosts?.slice(4, 10);
    slice3 = latestPosts?.slice(10, 20);
  }

  const [slice4, setSlice4] = useState<PostType[]>([]);
  const [HGSpinner, setHGSpinner] = useState(false);

  useEffect(() => {
    if (moreLatestPosts) {
      setSlice4([...slice4, ...moreLatestPosts]);
    }
    setHGSpinner(false);
  }, [moreLatestPosts]);

  useEffect(() => {
    setSlice4([]);
    setHGSpinner(false);
  }, []);

  const [loadMore, setLoadMore] = useState(true);
  const [page, setPage] = useState(1);
  const indexSubVal = page * 10;

  function homeGridLoadMore() {
    setHGSpinner(true);
    //for type error
    if (latestPosts) {
      //an index which iterates down from the bottom home grid post id by 10 each click
      if (parseInt(latestPosts[20].postId) - indexSubVal > 0) {
        getMoreLatestPosts(
          parseInt(latestPosts[20].postId) - indexSubVal,
          parseInt(latestPosts[20].postId) - indexSubVal + 9
        );
        setPage(page + 1);
      }
      //zero and hide button scenarios
      else {
        getMoreLatestPosts(0, 10);
        setTimeout(() => {
          setLoadMore(false); // needs a timeout atleast
        }, 1000);
      }
    }
    setTimeout(() => {
      setHGSpinner(false);
    }, 10000); //mobile latency
  }

  function validateEmail(emailAddress: string) {
    const isValid =
      !loading &&
      emailAddress.trim() !== '' &&
      !emailAddressWarning &&
      validate(emailAddress);
    return isValid;
  }

  useEffect(() => {
    if (searchText && isTagScreen) handleLatestArticlesClick();
  }, []);

  return (
    <div className='homepage-wrapper'>
      <Header
        loggedIn={!!user}
        isArticlePage={false}
        ScreenWidth={screenWidth}
        tokens={user?.nuaTokens}
        loading={false}
      />
      {/* {user ? '' : <LoggedOutSidebar responsiveElement={true} />} */}
      <div onFocus={() => setIsBlur(true)} onBlur={() => setIsBlur(false)}>
        <SearchBar
          value={searchText}
          onKeyDown={onKeyDown}
          onChange={(value) => setSearchText(value)}
        />
      </div>
      <div className={isBlur ? 'main blurred' : 'main'}>
        <div className='left'>
          {user ? (
            <div className='logged-in'>
              {/* Will call this dynamically when functionalities are implemented */}
              {/* <img className='avatar-pic' src={user?.avatar || assetPaths.DEFAULT_AVATAR} alt='' /> */}
              <div className='left-content'>
                <img
                  className='avatar'
                  alt=''
                  src={user?.avatar || images.DEFAULT_AVATAR}
                />
                <p className='name'>{user?.displayName}</p>

                <div className='handle'>
                  <Link to={`/${user?.handle}`}>@{user?.handle}</Link>
                </div>

                <div className='articles'>
                  <h6 className='leftt'>
                    <Link to='/my-profile/published'>
                      {counts?.publishedCount || 0} articles published
                    </Link>
                  </h6>
                  <h6>&nbsp;|&nbsp;</h6>
                  <h6 className='rightt'>
                    <Link to='/my-profile/draft'>
                      {counts?.draftCount || 0} articles in draft
                    </Link>
                  </h6>
                </div>
                <Button
                  styleType='secondary'
                  type='button'
                  style={{ width: '152px' }}
                  // icon={NONAME}
                  onClick={() => {
                    navigate('/article/new', { replace: true });
                  }}
                >
                  Create new article
                </Button>
              </div>
              <div className='email-opt-in-flex'>
                <img
                  src={icons.EMAIL_OPT_IN}
                  alt='background'
                  className={'img-email-sign-up-logged-in'}
                />
                <div
                  className={'email-sign-up-success'}
                  style={{ display: emailOptInSuccess ? 'inline' : 'none' }}
                >
                  <p className='email-sign-up-success-label-1-logged-in'>
                    Almost there!
                  </p>
                  <br></br>
                  <p className='email-sign-up-success-label-2-logged-in'>
                    To verify your subscription to FastBlocks News, be sure to
                    check our message in your inbox and click “confirm.”
                  </p>
                  <img
                    src={icons.EMAIL_OPT_IN_SUCCESS}
                    alt='background'
                    className={'img-email-sign-up-success-logged-in'}
                    style={{ display: emailOptInSuccess ? 'inline' : 'none' }}
                  />
                </div>

                <div
                  className={'email-sign-up'}
                  style={{ display: emailOptInSuccess ? 'none' : 'inline' }}
                >
                  Get all the latest crypto news on-chain. Sign up below and
                  never miss an article!
                </div>

                <div
                  className={'email-sign-up-2'}
                  style={{ display: emailOptInSuccess ? 'none' : 'inline' }}
                >
                  FastBlocks is the first fully-on-chain news source covering
                  crypto and Web3. Sign up for email reminders and make the
                  switch to a truly decentralized news source.
                </div>
                <br></br>
                <div
                  className={'input'}
                  style={{ display: emailOptInSuccess ? 'none' : 'inline' }}
                >
                  <label className='email-address-label'>E-MAIL ADDRESS</label>

                  <InputField2
                    width='265px'
                    height='24px'
                    defaultText='E-mail address'
                    fontSize='22px'
                    fontFamily='Roboto'
                    fontColor={colors.primaryTextColor}
                    hasError={emailAddressWarning}
                    value={emailAddress}
                    onChange={onEmailAddressChange}
                  ></InputField2>
                </div>
                <div style={{ position: 'relative', top: '-10px' }}>
                  <RequiredFieldMessage hasError={emailAddressWarning} />
                </div>
                <br></br>
                <Button
                  type='button'
                  styleType='email-opt-in'
                  style={{
                    width: '265px',
                    display: emailOptInSuccess ? 'none' : 'inline',
                  }}
                  onClick={onEmailOptIn}
                >
                  Get it!
                </Button>
              </div>
              <div className='left-menu'>
                <MeatBallSidebar />
              </div>
            </div>
          ) : (
            <div className={'logged-out'}>
              {/* Commenting out the FB Logo and Header as defined in PBI 867*/}

              {/* <img src={images.NUANCE_LOGO} style={{ width: '300px' }} />
              <h1>Smart Web3 Stories</h1> */}

              <img
                src={icons.EMAIL_OPT_IN}
                alt='background'
                className={'img-email-sign-up'}
              />
              <div
                className={'email-sign-up-success'}
                style={{ display: emailOptInSuccess ? 'inline' : 'none' }}
              >
                <p className='email-sign-up-success-label-1'>Almost there!</p>
                <p className='email-sign-up-success-label-2'>
                  To verify your subscription to FastBlocks News, be sure to
                  check our message in your inbox and click “confirm.”
                </p>
                <img
                  src={icons.EMAIL_OPT_IN_SUCCESS}
                  alt='background'
                  className={'img-email-sign-up-success'}
                  style={{ display: emailOptInSuccess ? 'inline' : 'none' }}
                />
              </div>

              <div
                className={'email-sign-up'}
                style={{ display: emailOptInSuccess ? 'none' : 'inline' }}
              >
                Get all the latest crypto news on-chain. Sign up below and never
                miss an article!
              </div>

              <div
                className={'email-sign-up-2'}
                style={{ display: emailOptInSuccess ? 'none' : 'inline' }}
              >
                FastBlocks is the first fully-on-chain news source covering
                crypto and Web3. Sign up for email reminders and make the switch
                to a truly decentralized news source.
              </div>
              <br></br>
              <div
                className={'input'}
                style={{ display: emailOptInSuccess ? 'none' : 'inline' }}
              >
                <label className='email-address-label'>E-MAIL ADDRESS</label>

                <InputField2
                  width='80%'
                  height='24px'
                  defaultText='E-mail address'
                  fontSize='22px'
                  fontFamily='Roboto'
                  fontColor={colors.primaryTextColor}
                  hasError={emailAddressWarning}
                  value={emailAddress}
                  onChange={onEmailAddressChange}
                ></InputField2>
              </div>
              <div style={{ position: 'relative', top: '-10px' }}>
                <RequiredFieldMessage hasError={emailAddressWarning} />
              </div>
              <br></br>
              <Button
                type='button'
                styleType='email-opt-in'
                style={{
                  width: '80%',
                  display: emailOptInSuccess ? 'none' : 'inline',
                }}
                onClick={onEmailOptIn}
              >
                Get it!
              </Button>

              {/* <p>
                Nuance is the world's first blogging platform built entirely on
                blockchain.
              </p>
              <p>Built on, and for, the new Web.</p> */}
              {/* </div> */}
            </div>
          )}
        </div>
        <div className='right'>
          {loading ? (
            <Loader />
          ) : (
            <div className='posts'>
              {showSearchResults ? (
                <div>
                  <div className='rowContainer'>
                    {searchedTag ? (
                      <div
                        className='button-attributes-primary-3'
                        style={{
                          minWidth: '75px',
                          cursor: 'default',
                          padding: '0 15px',
                        }}
                      >
                        {lastSearchPhrase}
                      </div>
                    ) : (
                      <Link to='/' className='link1'>
                        SEARCH RESULTS
                      </Link>
                    )}
                    <span className='span'> | </span>
                    <div className='sec'>
                      <Link
                        to='/'
                        className='link2'
                        onClick={handleLatestArticlesClick}
                      >
                        LATEST ARTICLES
                      </Link>
                    </div>
                  </div>
                  <div className='search-summary'>
                    <div className='search-count'>
                      <span className='pipe'>|</span>
                      Found {searchTotalCount}
                      {searchTotalCount == 1 ? ' article ' : ' articles '}
                      {searchedTag ? ' with the tag ' : ' for '}
                      <span className='result'> '{lastSearchPhrase}'</span>
                    </div>

                    {user && searchedTag && (
                      <div className='follow'>
                        <Button
                          styleType='secondary'
                          type='button'
                          style={{ width: '152px' }}
                          onClick={handleFollowClicked}
                          icon={
                            updatingFollow ? (
                              <SpinnerCircular
                                thickness={200}
                                color={colors.primaryColor}
                                secondaryColor={colors.accentColor}
                              />
                            ) : (
                              ''
                            )
                          }
                        >
                          {isFollowingTag ? 'Followed' : 'Follow this tag'}
                        </Button>
                      </div>
                    )}
                  </div>

                  <div className='article-grid-horizontal'>
                    {loadedSearchResults?.map((post: PostType) => (
                      <CardHorizontal post={post} key={post.postId} />
                    ))}
                  </div>
                  {(loadedSearchResults?.length || 0) < searchTotalCount && (
                    <div className='load-more-container'>
                      <Button
                        styleType='secondary'
                        style={{ width: '152px' }}
                        onClick={handleClickMore}
                        icon={loadingMore ? images.loaders.BUTTON_SPINNER : ''}
                      >
                        <span>Load More</span>
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div className='latestArticles'>
                  <p className='mainTitle'>LATEST ARTICLES</p>
                  <div className='article-grid'>
                    {slice1?.map((post: PostType) => (
                      <CardVertical post={post} key={post.postId} />
                    ))}

                    {screenWidth < 768 ? (
                      <>
                        <br></br>
                        <div className='article-grid' ref={refEmailOptInMobile}>
                          <img
                            src={icons.EMAIL_OPT_IN}
                            alt='background'
                            className={'img-email-sign-up'}
                          />
                          <br></br>
                          <div
                            className={'email-sign-up-success-mobile'}
                            style={{
                              display: emailOptInSuccess ? 'inline' : 'none',
                            }}
                          >
                            <p className='email-sign-up-success-label-1-logged-in'>
                              Almost there!
                            </p>
                            <br></br>
                            <p className='email-sign-up-success-label-2-logged-in'>
                              To verify your subscription to FastBlocks News, be
                              sure to check our message in your inbox and click
                              “confirm.”
                            </p>
                            <img
                              src={icons.EMAIL_OPT_IN_SUCCESS}
                              alt='background'
                              className={'img-email-sign-up-success-mobile'}
                              style={{
                                display: emailOptInSuccess ? 'inline' : 'none',
                              }}
                            />
                          </div>
                          <div
                            id='emailoptinmobile'
                            //ref={refEmailOptInMobile}
                            className={'email-sign-up'}
                            style={{
                              display: emailOptInSuccess ? 'none' : 'inline',
                            }}
                          >
                            Get all the latest crypto news on-chain. Sign up
                            below and never miss an article!
                          </div>

                          <div
                            className={'input'}
                            style={{
                              display: emailOptInSuccess ? 'none' : 'inline',
                            }}
                          >
                            <label className='email-address-label'>
                              E-MAIL ADDRESS
                            </label>

                            <InputField2
                              width='265px'
                              height='24px'
                              defaultText='E-mail address'
                              fontSize='22px'
                              fontFamily='Roboto'
                              fontColor={colors.primaryTextColor}
                              hasError={emailAddressWarning}
                              value={emailAddress}
                              onChange={onEmailAddressChange}
                            ></InputField2>
                          </div>
                          <div style={{ position: 'relative', top: '-10px' }}>
                            <RequiredFieldMessage
                              hasError={emailAddressWarning}
                            />
                          </div>
                          <br></br>
                          <Button
                            type='button'
                            styleType='email-opt-in'
                            style={{
                              width: '265px',
                              display: emailOptInSuccess ? 'none' : 'inline',
                            }}
                            onClick={onEmailOptIn}
                          >
                            Get it!
                          </Button>
                        </div>
                        <br></br>
                        <br></br>
                      </>
                    ) : null}
                    {slice2?.map((post: PostType) => (
                      <CardVertical post={post} key={post.postId} />
                    ))}
                  </div>
                  <div className='article-grid-horizontal'>
                    {slice3?.map((post: PostType) => (
                      <CardHorizontal post={post} key={post.postId} />
                    ))}
                  </div>
                  <div className='article-grid-horizontal'>
                    {slice4?.map((post: PostType) => (
                      <CardHorizontal post={post} key={post.postId} />
                    ))}
                  </div>
                  <div
                    className={
                      loadMore ? 'home-grid-load-more-container' : 'hide'
                    }
                  >
                    <Button
                      styleType='secondary'
                      style={{ width: '152px' }}
                      onClick={homeGridLoadMore}
                      icon={
                        HGSpinner ? (
                          <SpinnerCircular
                            style={{ marginRight: '10px' }}
                            speed={150}
                            size={20}
                            thickness={250}
                            color={colors.primaryColor}
                            secondaryColor={colors.accentColor}
                          />
                        ) : (
                          ''
                        )
                      }
                    >
                      <span>Load More</span>
                    </Button>
                  </div>
                </div>
              )}
              <Footer />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePageGrid;
