export const idlFactory = ({ IDL }) => {
  const ClearIndexResult = IDL.Variant({ 'ok' : IDL.Nat, 'err' : IDL.Text });
  const Result_1 = IDL.Variant({ 'ok' : IDL.Vec(IDL.Text), 'err' : IDL.Text });
  const IndexPostResult = IDL.Variant({ 'ok' : IDL.Text, 'err' : IDL.Text });
  const Result = IDL.Variant({ 'ok' : IDL.Null, 'err' : IDL.Text });
  const SearchResultData = IDL.Record({
    'postIds' : IDL.Vec(IDL.Text),
    'totalCount' : IDL.Text,
  });
  return IDL.Service({
    'clearIndex' : IDL.Func([], [ClearIndexResult], []),
    'getAdmins' : IDL.Func([], [Result_1], ['query']),
    'getCgUsers' : IDL.Func([], [Result_1], ['query']),
    'indexPost' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text, IDL.Vec(IDL.Text), IDL.Vec(IDL.Text)],
        [IndexPostResult],
        [],
      ),
    'indexSize' : IDL.Func([], [IDL.Nat], ['query']),
    'registerAdmin' : IDL.Func([IDL.Text], [Result], []),
    'search' : IDL.Func(
        [IDL.Text, IDL.Bool, IDL.Nat32, IDL.Nat32],
        [SearchResultData],
        ['query'],
      ),
    'unregisterAdmin' : IDL.Func([IDL.Text], [Result], []),
  });
};
export const init = ({ IDL }) => { return []; };
