import React, { useState, useEffect } from 'react';
import { useUserStore, usePostStore } from '../../store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import { icons } from '../../shared/constants';

type MyProfileSidebarProps = {
  isSidebarToggle?: (e: any) => void;
  id?: String;
  handle?: String;
};

const MyProfileSidebar: React.FC<MyProfileSidebarProps> = (
  props
): JSX.Element => {
  const navigate = useNavigate();
  const [shown, setShown] = useState(false);

  const location = useLocation();

  const { user, getCounts, counts } = useUserStore((state) => ({
    user: state.user,
    getCounts: state.getUserPostCounts,
    counts: state.userPostCounts,
  }));

  const { getMyTags, myTags } = usePostStore((state) => ({
    getMyTags: state.getMyTags,
    myTags: state.myTags,
  }));

  useEffect(() => {
    if (user) {
      getCounts(user.handle);
      getMyTags();
    }
  }, [user]);

  const CloseMenu = () => {
    setShown(false);
  };

  const ToggleMenu = () => {
    setShown(!shown);
    props.isSidebarToggle && props.isSidebarToggle(!shown);
  };

  const sidebarRoutes = [
    {
      title: 'My Profile',
      goto: '/my-profile',
    },
    {
      title: `Draft Articles (${counts?.draftCount || 0})`,
      goto: '/my-profile/draft',
    },
    {
      title: `Published Articles (${counts?.publishedCount || 0})`,
      goto: '/my-profile/published',
    },
    {
      title: `Followed Topics (${myTags?.length || 0})`,
      goto: '/my-profile/topics',
    },
    {
      title: `Following (${user?.followersArray.length || 0})`,
      goto: '/my-profile/following',
    },
  ];

  return (
    <div className='meatball-menu'>
      <img
        className='sidebar-button'
        onClick={ToggleMenu}
        src={shown ? icons.THREE_DOTS_BLUE : icons.THREE_DOTS}
        alt='sidebar-button'
      />
      {
        <div
          className='sidebar-content'
          onMouseLeave={CloseMenu}
          style={shown ? { width: 230 } : { width: 0 }}
        >
          <div style={shown ? {} : { display: 'none' }}>
            <div className='sidebar-dropdown'>
              {sidebarRoutes.map((route) => {
                return (
                  <Link
                    className={`route ${
                      location.pathname === route?.goto && 'active'
                    }`}
                    key={route?.goto}
                    to={route?.goto}
                  >
                    {route?.title}
                  </Link>
                );
              })}
              <div className='hr' />
              <Button
                styleType='secondary'
                type='button'
                style={{ width: '130px' }}
                onClick={() => {
                  navigate('/article/new', { replace: true });
                }}
              >
                Create new article
              </Button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default MyProfileSidebar;
