import React from 'react';
import { SpinnerInfinity } from 'spinners-react';
import { colors, images } from '../../shared/constants';

const Loader = () => {
  return (
    <SpinnerInfinity
      speed={100}
      size={200}
      thickness={200}
      color={colors.primaryColor}
      secondaryColor={colors.accentColor}
    />
  );
  // <img src={images.loaders.NUANCE_LOADER} alt='Aikin loading image' />;
};

export default Loader;
