import React, { useEffect } from 'react';
import Button from '../../UI/Button/Button';
import { useNavigate } from 'react-router';
import { useUserStore, useAuthStore } from '../../store';
import Footer from '../../components/footer/footer';
import { images } from '../../shared/constants';
import Linkify from 'react-linkify';

const MyProfile = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const { user, getUser, counts } = useUserStore((state) => ({
    user: state.user,
    getUser: state.getUser,
    counts: state.userPostCounts,
  }));

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (isLoggedIn && !user) {
      navigate('/', { replace: true });
    }
  }, [isLoggedIn, user]);

  const goToProfileEditPage = () => {
    navigate('/my-profile/edit', { replace: true });
  };

  //for customizing linkify npm package
  const componentDecorator = (href: any, text: any, key: any) => (
    <a href={href} key={key} target='_blank' rel='noopener noreferrer'>
      {text}
    </a>
  );

  return (
    <div className='wrapper'>
      <p className='title'>MY PROFILE</p>
      <div style={{ alignSelf: 'flex-end', marginTop: '20px' }}>
        {window.innerWidth < 768 ? (
          <Button
            styleType='secondary'
            type='button'
            style={{ width: '96px' }}
            onClick={goToProfileEditPage}
          >
            Edit
          </Button>
        ) : (
          <Button
            styleType='secondary'
            type='button'
            style={{ width: '96px' }}
            onClick={goToProfileEditPage}
          >
            Edit profile
          </Button>
        )}
      </div>
      <div className='content'>
        <img
          className='image'
          alt=''
          src={user?.avatar || images.DEFAULT_AVATAR}
        />
        <p className='name'>{user?.displayName}</p>
        <p className='username'>@{user?.handle}</p>
        <Linkify componentDecorator={componentDecorator}>
          <p className='description'>{user?.bio}</p>
        </Linkify>
      </div>
      <div
        className='statistic'
        style={{ marginBottom: '150px', marginTop: '30px' }}
      >
        <div className='statistic'>
          <div className='stat'>
            <p className='count'>{counts?.totalPostCount}</p>
            <p className='title'>Articles</p>
          </div>
          <div className='stat'>
            <p className='count'>{counts?.totalViewCount || 0}</p>
            <p className='title'>Article Views</p>
          </div>
          {/* <div className='stat'>
            <p className='count'>{counts?.uniqueClaps || 0}</p>
            <p className='title'>Claps</p>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyProfile;
