import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../../store';
import { images, icons } from '../../shared/constants';

type ReadArticleSidebarProps = {
  isSidebarToggle?: (e: any) => void;
  url: string;
  id?: string;
  handle?: string;
  avatar?: string;
};

const ReadArticleSidebar: React.FC<ReadArticleSidebarProps> = (
  props
): JSX.Element => {
  const [shown, setShown] = useState(false);
  const [isAuthorAndReader, setIsAuthorAndReader] = useState(false);

  const author = useUserStore((state) => state.author);
  const user = useUserStore((state) => state.user);

  const CloseMenu = () => {
    setShown(false);
  };

  const ToggleMenu = () => {
    setShown(!shown);
    props.isSidebarToggle && props.isSidebarToggle(!shown);
  };

  const copyLinkToArticle = () => {
    if (
      window.location.origin == 'https://exwqn-uaaaa-aaaaf-qaeaa-cai.ic0.app'
    ) {
      navigator.clipboard.writeText('http://www.nuance.xyz' + props.url);
    } else {
      navigator.clipboard.writeText(window.location.origin + props.url);
    }

    CloseMenu();
    props.isSidebarToggle && props.isSidebarToggle(false);
  };

  const reportArticle = () => {
    console.log('Logic for reporting article...');
  };

  let navigate = useNavigate();

  function editArticle() {
    navigate('/article/edit/' + props.id);
  }

  function authorReaderCheck() {
    return props.handle == user?.handle;
  }

  useEffect(() => {
    setIsAuthorAndReader(authorReaderCheck());
  }, [props.handle]);

  return (
    <div
      className='read-article-sidebar-menu'
      style={shown ? { width: '230px' } : { width: 0 }}
    >
      <img
        className='sidebar-button'
        onClick={ToggleMenu}
        src={shown ? icons.THREE_DOTS_BLUE : icons.THREE_DOTS}
        alt='sidebar-button'
      />
      {
        <div
          className='sidebar-content'
          style={shown ? { width: '230px' } : { width: 0 }}
        >
          <div
            className='left-content-sidebar'
            style={shown ? {} : { display: 'none' }}
          >
            <div className='menus'>
              <a className='links' onClick={copyLinkToArticle}>
                Copy link to article
              </a>
              <a className='links' onClick={reportArticle}>
                Report article
              </a>
              {isAuthorAndReader ? (
                <a className='links' onClick={editArticle}>
                  Edit article
                </a>
              ) : null}
            </div>
            <div className='horizontal-divider'></div>
            {author && (
              <div className='author'>
                <img src={props.avatar || images.DEFAULT_AVATAR} alt=''></img>
                <a>@{props.handle}</a>
              </div>
            )}
            <div className='horizontal-divider'></div>
          </div>
        </div>
      }
    </div>
  );
};

export default ReadArticleSidebar;
