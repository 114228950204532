import create, { GetState, SetState, StateCreator, StoreApi } from 'zustand';
import { persist } from 'zustand/middleware';
import { toastError } from '../services/toastService';
import { ErrorType, getErrorType } from '../services/errorService';
import { useAuthStore } from './';
import { PostType } from '../types/types';
import {
  getPostActor,
  getPostIndexActor,
  getUserActor,
  User,
  PostSaveModel,
  PostTag,
  PostTagModel,
  TagModel,
} from '../services/actorService';

const Err = 'err';
const Unexpected = 'Unexpected error: ';
const ArticleNotFound = 'Article not found';

// fetch and merge author avatars into a list of posts
const mergeAuthorAvatars = async (posts: PostType[]): Promise<PostType[]> => {
  const authorHandles = posts.map((p) => p.handle);

  const authors = await (await getUserActor()).getUsersByHandles(authorHandles);

  return posts.map((p) => {
    const author = authors.find((a) => a.handle === p.handle);
    if (author) {
      return { ...p, avatar: author.avatar };
    }
    return p;
  });
};

const handleError = (err: any, preText?: string) => {
  const errorType = getErrorType(err);

  if (errorType == ErrorType.SessionTimeOut) {
    useAuthStore?.getState().logout();
    window.location.href = '/timed-out';
  } else {
    toastError(err, preText);
  }
};

export interface PostStore {
  count: bigint | undefined;
  post: PostType | undefined;
  author: User | undefined;
  deletedPostId: string | undefined;
  savedPost: PostType | undefined;
  userPosts: PostType[] | undefined;
  myDraftPosts: PostType[] | undefined;
  myPublishedPosts: PostType[] | undefined;
  claps: string | undefined;
  isTagScreen: boolean;

  searchText: string;
  searchResults: PostType[] | undefined;
  searchTotalCount: Number;
  latestPosts: PostType[] | undefined;
  moreLatestPosts: PostType[] | undefined;
  postTotalCount: Number;

  getPostError: string | undefined;
  getSavedPostError: string | undefined;

  allTags: TagModel[] | undefined;
  myTags: PostTagModel[] | undefined;
  tagsByUser: PostTag[] | undefined;

  savePost: (post: PostSaveModel) => Promise<void>;
  getSavedPost: (postId: string) => Promise<void>;
  clearSavedPost: () => Promise<void>;
  clearSavedPostError: () => Promise<void>;
  deletePost: (postId: string) => Promise<void>;
  getTotalPostCount: () => Promise<void>;
  getPost: (handle: string, postId: string) => Promise<void>;
  clearPost: () => void;
  //clearPostError: () => Promise<void>;
  getUserPosts: (handle: string) => Promise<void>;
  getMyDraftPosts: (indexFrom: number, indexTo: number) => Promise<void>;
  getMyPublishedPosts: (indexFrom: number, indexTo: number) => Promise<void>;
  getLatestPosts: (indexFrom: number, indexTo: number) => Promise<void>;
  getMoreLatestPosts: (indexFrom: number, indexTo: number) => Promise<void>;
  clapPost: (postId: string) => Promise<void>;
  clearSearchBar: (isTagScreen: boolean ) => void;

  setSearchText: (searchText: string) => void;
  search: (
    phrase: string,
    isTagSearch: boolean,
    indexFrom: number,
    indexTo: number
  ) => Promise<void>;
  clearSearch: () => void;

  getAllTags: () => Promise<void>;
  getMyTags: () => Promise<void>;
  getTagsByUser: (userId: string) => Promise<void>;
  followTag: (tag: string) => Promise<void>;
  unfollowTag: (tag: string) => Promise<void>;

  clearAll: () => void;
}

// proxies calls to the app canister and caches the results
const createPostStore: StateCreator<PostStore> | StoreApi<PostStore> = (
  set,
  get
) => ({
  count: undefined,
  post: undefined,
  author: undefined,
  deletedPostId: undefined,
  savedPost: undefined,
  userPosts: undefined,
  myDraftPosts: undefined,
  myPublishedPosts: undefined,
  claps: undefined,
  ClearSearchBar: false,
  isTagScreen: false,

  searchText: '',
  searchResults: undefined,
  searchTotalCount: 0,
  postTotalCount: 0,
  latestPosts: undefined,
  moreLatestPosts: undefined,

  getPostError: undefined,
  getSavedPostError: undefined,

  allTags: undefined,
  myTags: undefined,
  tagsByUser: undefined,

  clearSearchBar(isTagScreen) {
    set((state) => {
      state.isTagScreen = isTagScreen;
    });
  },

  savePost: async (post: PostSaveModel): Promise<void> => {
    try {
      const result = await (await getPostActor()).save(post);
      if (Err in result) {
        toastError(result.err);
      } else {
        set({ savedPost: result.ok as PostType });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  getSavedPost: async (postId: string): Promise<void> => {
    try {
      const result = await (await getPostActor()).get(postId);
      if (Err in result) {
        set({ getSavedPostError: result.err });
        toastError(result.err);
      } else {
        const savedPost = result.ok as PostType;
        set({ savedPost, getSavedPostError: undefined });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  clearSavedPost: async (): Promise<void> => {
    set({ savedPost: undefined });
  },

  clearSavedPostError: async (): Promise<void> => {
    set({ getSavedPostError: undefined });
  },

  deletePost: async (postId: string): Promise<void> => {
    try {
      const result = await (await getPostActor()).delete(postId);
      if (Err in result) {
        toastError(result.err);
      } else {
        set({ deletedPostId: postId });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  getTotalPostCount: async (): Promise<void> => {
    try {
      const count = await (await getPostActor()).getTotalPostCount();
      set({ count });
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  getPost: async (handle: string, postId: string): Promise<void> => {
    try {
      // parallel requests for author and posts
      // fetch the post's author for the avatar
      // also used to verify the author in the url
      let res: any[] = await Promise.all([
        (await getUserActor()).getUserByHandle(handle),
        (await getPostActor()).get(postId),
      ]);

      let authorResult = res[0];
      let postResult = res[1];

      if (Err in authorResult) {
        set({ getPostError: `User not found for @${handle}` });
        toastError(authorResult.err);
      } else if (Err in postResult) {
        set({ getPostError: ArticleNotFound });
        toastError(postResult.err);
      } else {
        const post = postResult.ok as PostType;
        const author = authorResult.ok as User;
        if (author.handle !== post.handle) {
          set({ getPostError: `Article not found for @${handle}` });
        } else {
          set({ post, author, getPostError: undefined });
        }
        // fire and forget (increments view count for post)
        (await getPostActor()).viewPost(postId);
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  clearPost: (): void => {
    set({ post: undefined, author: undefined });
  },

  getUserPosts: async (handle: string): Promise<void> => {
    try {
      const userPosts = (await (
        await getPostActor()
      ).getUserPosts(handle)) as PostType[];

      set({ userPosts });

      const postsWithAvatars = await mergeAuthorAvatars(userPosts);

      set({ userPosts: postsWithAvatars });
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  getMyDraftPosts: async (
    indexFrom: number,
    indexTo: number
  ): Promise<void> => {
    try {
      const myDraftPosts = (await (
        await getPostActor()
      ).getMyPosts(
        true, //includeDraft
        false, //includePublished
        indexFrom,
        indexTo
      )) as PostType[];
      set({ myDraftPosts });

      const postsWithAvatars = await mergeAuthorAvatars(myDraftPosts);
      set({ myDraftPosts: postsWithAvatars });
    } catch (err: any) {
      handleError(err, Unexpected);
    }
  },

  getMyPublishedPosts: async (
    indexFrom: number,
    indexTo: number
  ): Promise<void> => {
    try {
      const myPublishedPosts = (await (
        await getPostActor()
      ).getMyPosts(
        false, //includeDraft
        true, //includePublished
        indexFrom,
        indexTo
      )) as PostType[];

      set({ myPublishedPosts });

      const postsWithAvatars = await mergeAuthorAvatars(myPublishedPosts);

      set({ myPublishedPosts: postsWithAvatars });
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  getLatestPosts: async (indexFrom, indexTo): Promise<void> => {
    try {
      const actor = await getPostActor();
      const results = await actor.getLatestPosts(indexFrom, indexTo);
      const posts = results.posts;
      const totalCount = results.totalCount;
      if (posts?.length) {
        const postsWithAvatars = await mergeAuthorAvatars(posts);
        set({ latestPosts: postsWithAvatars, postTotalCount: Number(totalCount) || 0 });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },
      
  

  //get more latest posts similar to load more search results

  getMoreLatestPosts: async (indexFrom, indexTo): Promise<void> => {
    try {
      let moreLatestPosts = (await (
        await getPostActor()
      ).getMoreLatestPosts(indexFrom, indexTo)) as PostType[];

      moreLatestPosts = moreLatestPosts.sort(
        (a, b) => Number(b.postId) - Number(a.postId)
      );
     
      const postsWithAvatars = await mergeAuthorAvatars(moreLatestPosts);
      
      set({ moreLatestPosts: postsWithAvatars });
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  setSearchText: (searchText: string): void => {
    set({ searchText });
  },

  search: async (
    phrase: string,
    isTagSearch: boolean,
    indexFrom: number,
    indexTo: number
  ): Promise<void> => {
    try {
      const actor = await getPostIndexActor();
      const results = await actor.search(
        phrase,
        isTagSearch,
        indexFrom,
        indexTo
      );

      const postIds = results.postIds;

      if (postIds?.length) {
        const posts = (await (
          await getPostActor()
        ).getList(postIds)) as PostType[];

        if (posts?.length) {
          const postsWithAvatars = await mergeAuthorAvatars(posts);
          set({
            searchTotalCount: Number(results.totalCount || 0),
            searchResults: postsWithAvatars,
          });
          return;
        }
      }

      set({
        searchTotalCount: 0,
        searchResults: [],
      });
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  clearSearch: (): void => {
    set({ searchText: '', searchResults: undefined });
  },

  getAllTags: async (): Promise<void> => {
    try {
      if (!(get().allTags || []).length) {
        const allTags = await (await getPostActor()).getAllTags();
        allTags.sort((a, b) => a.value.localeCompare(b.value));
        set({ allTags });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  getMyTags: async (): Promise<void> => {
    try {
      const myTags = await (await getPostActor()).getMyTags();
      set({ myTags });
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  getTagsByUser: async (userId: string): Promise<void> => {
    try {
      const tagsByUser = await (await getPostActor()).getTagsByUser(userId);
      set({ tagsByUser });
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  followTag: async (tagId: string): Promise<void> => {
    try {
      const result = await (await getPostActor()).followTag(tagId);
      if (Err in result) {
        toastError(result.err);
      } else {
        await get().getMyTags();
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  clapPost: async (postId: string): Promise<void> => {
    try {
      const result = await (await getPostActor()).clapPost(postId);
    } catch (err) {
      handleError(err, Unexpected);
    }
  },
  
  unfollowTag: async (tagId: string): Promise<void> => {
    try {
      const result = await (await getPostActor()).unfollowTag(tagId);
      if (Err in result) {
        toastError(result.err);
      } else {
        await get().getMyTags();
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  clearAll: (): void => {
    set({}, true);
  },
});

export const usePostStore = create<PostStore>(
  persist(
    (set, get, api) => ({
      ...createPostStore(
        set as SetState<PostStore>,
        get as GetState<PostStore>,
        api as StoreApi<PostStore>
      ),
    }),
    {
      name: 'postStore',
      getStorage: () => sessionStorage,
    }
  )
);
