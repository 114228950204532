import React, { useEffect } from 'react';
import { usePostStore, useUserStore, useAuthStore } from '../../store';
import { images } from '../../shared/constants';
import FollowAuthor from '../../components/follow-author/follow-author';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Following = () => {
  // This component is a child of profileSidebar

  usePostStore((state) => state);

  const { user, getAuthor, author } = useUserStore((state) => ({
    user: state.user,
    getAuthor: state.getAuthor,
    author: state.author,
  }));

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn && !user) {
      navigate('/', { replace: true });
    }
  }, [isLoggedIn, user]);

  const followersList = user?.followersArray?.map((follower) => (
    <div className='list-container'>
      <li className='follower-container'>
        <FollowAuthor
          AuthorHandle={follower}
          Followers={user.followersArray}
          user={user.handle}
        >
          {' '}
        </FollowAuthor>
        <div className='follower-handle'>
          <Link to={`/${follower}`} className='handle'>
            @{follower}
          </Link>
        </div>
        <img
          src={images.DEFAULT_AVATAR}
          alt='background'
          className='followers-list-image'
        />
      </li>
    </div>
  ));

  return (
    <div className='followers-wrapper'>
      <p className='title'>FOLLOWING({user?.followersArray.length || 0})</p>
      <div className='content'>
        <ul>{followersList}</ul>
      </div>
    </div>
  );
};

export default Following;
