import React from 'react';
import { useAuthStore } from '../../store';
import Button from '../../UI/Button/Button';
import { images } from '../../shared/constants';

type LoggedOutProps = {
  responsiveElement: Boolean;
};

const LoggedOutSidebar: React.FC<LoggedOutProps> = (props): JSX.Element => {
  //redirecting to register pages is handled in the HomeGrid component.
  const login = useAuthStore((state) => state.login);

  let nameOfClass;
  {
    props.responsiveElement
      ? (nameOfClass = 'logged-out-responsive')
      : (nameOfClass = 'logged-out');
  }

  return (
    <div className='wrapper'>
      <div className={nameOfClass}>
        <img style={{width: "300px"}} src={images.NUANCE_LOGO} />
        <p>Smart Web3 Stories</p>
        {props.responsiveElement ? (
          <>
            {/* <p>
              Nuance is the world's first blogging platform built entirely on
              blockchain.
            </p>
            <p>Built on, and for, the new Web.</p> */}
          </>
        ) : (
          <div>
            {/* <p>
              Nuance is the world's first blogging platform built entirely on
              blockchain.
            </p>
            <p>Built on, and for, the new Web.</p> */}
          </div>
        )}

        <div className='buttons'>
          <div className='button'>
            <Button
              styleType='primary-1'
              type='button'
              style={{ width: '265px' }}
              // icon={NONAME}
              onClick={() => login('ii')}
            >
              Log in with Internet Identity
            </Button>
          </div>
          <div className='button'>
            <Button
              styleType='primary-1'
              type='button'
              style={{ width: '265px' }}
              // icon={NONAME}
              onClick={() => login('stoic')}
            >
              Log in with Stoic
            </Button>
          </div>
          <div className='button'>
            <Button
              styleType='primary-3'
              type='button'
              style={{ width: '265px' }}
              // icon={NONAME}
              onClick={() => login('ii')}
            >
              Register with Internet Identity
            </Button>
          </div>
          <div className='button'>
            <Button
              styleType='primary-3'
              type='button'
              style={{ width: '265px' }}
              // icon={NONAME}
              onClick={() => login('stoic')}
            >
              Register with Stoic
            </Button>
          </div>

          {props.responsiveElement ? (
            <a>
              <p>Learn about Nuance</p>
            </a>
          ) : (
            <p className='identity'>
              <a
                href='https://smartcontracts.org/docs/ic-identity-guide/what-is-ic-identity.html'
                target='_blank'
              >
                What is internet identity?
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoggedOutSidebar;
