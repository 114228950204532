import create, { GetState, SetState, StateCreator, StoreApi } from 'zustand';
import { persist } from 'zustand/middleware';
import { toastError } from '../services/toastService';
import { ErrorType, getErrorType } from '../services/errorService';
import { useAuthStore } from './';
import { UserType, UserListItem } from '../types/types';
import {
  getUserActor,
  getPostActor,
  User,
  Followers,
  UserPostCounts,
  getEmailOptInActor,
} from '../services/actorService';

const Err = 'err';
const Unexpected = 'Unexpected error: ';
const UserNotFound = 'User not found';

const handleError = (err: any, preText?: string) => {
  const errorType = getErrorType(err);

  if (errorType == ErrorType.SessionTimeOut) {
    useAuthStore?.getState().logout();
    console.log('Logged out: ' + new Date());
    window.location.href = '/timed-out';
  } else {
    toastError(err, preText);
  }
};

export interface UserStore {
  readonly user: UserType | undefined;
  readonly userPostCounts: UserPostCounts | undefined;
  readonly author: UserType | undefined;
  readonly unregistered: boolean;
  readonly followers: Followers | undefined;

  registerUser: (
    handle: string,
    displayName: string,
    avatar: string
  ) => Promise<void>;
  getUser: () => Promise<void>;
  getAuthor: (handle: string) => Promise<void>;
  getUserPostCounts: (handle: string) => Promise<void>;
  clearUser: () => Promise<void>;
  updateDisplayName: (displayName: string) => Promise<void>;
  updateAvatar: (avatarUrl: string) => Promise<void>;
  updateBio: (bio: string) => Promise<void>;
  followAuthor: (author: string) => Promise<void>;
  unfollowAuthor: (author: string) => Promise<void>;
  createEmailOptInAddress: (emailAddress: string) => Promise<void>;

  clearAll: () => void;
}

const toUserModel = (user: User): UserType => {
  return {
    ...user,
    lastLogin: 0,
    followedTags: [],
  } as UserType;
};

// proxies calls to the app canister and caches the results
// todo: add error handling
const createUserStore: StateCreator<UserStore> | StoreApi<UserStore> = (
  set,
  get
) => ({
  user: undefined,
  userPostCounts: undefined,
  author: undefined,
  unregistered: true,
  followers: undefined,
  getUsersByHandlesReturn: [],

  registerUser: async (
    handle: string,
    displayName: string,
    avatar: string
  ): Promise<void> => {
    try {
      const result = await (
        await getUserActor()
      ).registerUser(handle, displayName, avatar);
      if (Err in result) {
        toastError(result.err);
      } else {
        set({ user: toUserModel(result.ok) });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  getUser: async (): Promise<void> => {
    try {
      const result = await (await getUserActor()).getUser();
      if (Err in result) {
        set({
          user: undefined,
          unregistered: result.err == UserNotFound,
        });
        if (result.err) {
          console.log('getUser: ' + result.err);
        }
        // this function is called when the app loads to check
        // if the user exists, so it should not display an error
      } else {
        let user = toUserModel(result.ok);
        set({
          user,
          unregistered: false,
        });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  getUserPostCounts: async (handle: string): Promise<void> => {
    try {
      const userPostCounts = await (
        await getPostActor()
      ).getUserPostCounts(handle);

      set({ userPostCounts });
    } catch (err) {
      toastError(err, Unexpected);
    }
  },

  getAuthor: async (handle: string): Promise<void> => {
    try {
      const result = await (
        await getUserActor()
      ).getUserByHandle(handle.trim());
      if (Err in result) {
        set({ author: undefined });
        toastError(result.err);
      } else {
        set({ author: toUserModel(result.ok) });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  clearUser: async (): Promise<void> => {
    set({ user: undefined });
  },

  updateDisplayName: async (displayName: string): Promise<void> => {
    try {
      const result = await (
        await getUserActor()
      ).updateDisplayName(displayName);
      if (Err in result) {
        toastError(result.err);
      } else {
        set({ user: toUserModel(result.ok) });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  updateBio: async (bio: string): Promise<void> => {
    try {
      const result = await (await getUserActor()).updateBio(bio);
      if (Err in result) {
        toastError(result.err);
      } else {
        set({ user: toUserModel(result.ok) });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  updateAvatar: async (avatar: string): Promise<void> => {
    try {
      const result = await (await getUserActor()).updateAvatar(avatar);
      if (Err in result) {
        toastError(result.err);
      } else {
        set({ user: toUserModel(result.ok) });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  followAuthor: async (author: string): Promise<void> => {
    try {
      const result = await (await getUserActor()).followAuthor(author);
      if (Err in result) {
        toastError(result.err);
      } else {
        set({ user: toUserModel(result.ok) });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },
  unfollowAuthor: async (author: string): Promise<void> => {
    try {
      const result = await (await getUserActor()).unfollowAuthor(author);
      if (Err in result) {
        toastError(result.err);
      } else {
        set({ user: toUserModel(result.ok) });
      }
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  createEmailOptInAddress: async (emailAddress: string): Promise<void> => {
    try {
      const result = await (
        await getEmailOptInActor()
      ).createEmailOptInAddress(emailAddress);
    } catch (err) {
      handleError(err, Unexpected);
    }
  },

  clearAll: (): void => {
    set({}, true);
  },
});

export const useUserStore = create<UserStore>(
  persist(
    (set, get, api) => ({
      ...createUserStore(
        set as SetState<UserStore>,
        get as GetState<UserStore>,
        api as StoreApi<UserStore>
      ),
    }),
    {
      name: 'userStore',
      getStorage: () => sessionStorage,
    }
  )
);
