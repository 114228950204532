import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
//import { Usergeek } from 'usergeek-ic-js';

import './index.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//init usergeek
// see: https://github.com/usergeek/usergeek-ic-js
// if (process.env.NODE_ENV === 'development') {
//   Usergeek.init({
//     apiKey: '01250195F1B483F42A75ABA84EB2B8C9',
//     host: 'https://fbbjb-oyaaa-aaaah-qaojq-cai.raw.ic0.app/',
//   });
// } else {
//   Usergeek.init({ apiKey: '014101A1B74D0300C4A41017FD0F06F7' });
// }
