import React, { useState, useEffect } from 'react';
import { colors, icons } from '../../shared/constants';
import ReaderAndAuthor from '../../UI/reader-and-author-edit/reader-and-author-edit';
import { usePostStore, useUserStore } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

type ReportArticleProps = {
  id: string;
  handle: string;
  url: string;
  shown: boolean;
  setShown: React.Dispatch<React.SetStateAction<boolean>>;
};

//report article logic
const ReportArticle: React.FC<ReportArticleProps> = (props): JSX.Element => {
  const CloseMenu = () => {
    props.setShown(false);
  };

  const copyLinkToArticle = () => {
    navigator.clipboard.writeText(window.location.origin + props.url);
    CloseMenu();
  };

  //follow author logic

  usePostStore((state) => state);

  const { user, getAuthor, author } = useUserStore((state) => ({
    user: state.user,
    getAuthor: state.getAuthor,
    author: state.author,
  }));
  const { followAuthor, unfollowAuthor } = useUserStore((state) => ({
    followAuthor: state.followAuthor,
    unfollowAuthor: state.unfollowAuthor,
  }));

  const [following, setFollowing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);

  function handleFollow() {
    if (!user?.followersArray || user.followersArray.includes(props.handle)) {
      return;
    }

    if (props?.handle) {
      followAuthor(props?.handle);
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 10000);
    }
  }

  useEffect(() => {
    if (user && props.handle) {
      setFollowing(user.followersArray.includes(props.handle));
      setLoading(false);
    }
  }, [user?.followersArray, props.handle]);

  return (
    <div className='meatball-menu'>
      <FontAwesomeIcon
        onClick={() => {
          props.setShown(!props.shown);
        }}
        // src={props.shown ? icons.THREE_DOTS_BLUE : icons.THREE_DOTS}
        // alt='copy-article-menu'
        icon={faEllipsis}
        style={hover ? { cursor: 'pointer', color: colors.accentColor } : {color: colors.primaryColor}}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />

      <div
        className='left-drop-down-content'
        onMouseLeave={CloseMenu}
        style={
          props.shown
            ? {
                height: 82,
                boxShadow: '0px 2px 10px 5px rgba(117, 117, 117, 0.08)',
              }
            : { height: 0 }
        }
      >
        <ul style={props.shown ? {} : { display: 'none' }}>
          <div onClick={copyLinkToArticle}>
            <a>
              <li style={{ cursor: 'hand' }}>Report Article</li>
            </a>
            <ReaderAndAuthor id={props.id} handle={props.handle} />
            {following || !user ? null : (
              <a>
                <li onClick={handleFollow} style={{ cursor: 'hand' }}>
                  Follow Author
                </li>
              </a>
            )}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default ReportArticle;
