import React, { useEffect, useState } from 'react';
import { Link, useLocation, Outlet, useNavigate } from 'react-router-dom';
import { useAuthStore, useUserStore, usePostStore } from '../../store';
import Header from '../../components/header/header';
import Button from '../../UI/Button/Button';
import MyProfileSidebar from '../../UI/my-profile-sidebar/my-profile-sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

const ProfileSidebar = () => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [screenWidth, setScreenWidth] = useState(0);
  const navigate = useNavigate();
  const [mobile, setMobile] = useState<Boolean>(false);
  const location = useLocation();

  const { user, getCounts, counts } = useUserStore((state) => ({
    user: state.user,
    getCounts: state.getUserPostCounts,
    counts: state.userPostCounts,
  }));

  const { getMyTags, myTags } = usePostStore((state) => ({
    getMyTags: state.getMyTags,
    myTags: state.myTags,
  }));

  useEffect(() => {
    if (user) {
      getCounts(user.handle);
      getMyTags();
    }
  }, [user]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setMobile(true);
    } else setMobile(false);
  }, [window.innerWidth]);

  useEffect(
    (window.onresize = window.onload =
      () => {
        setScreenWidth(window.innerWidth);
      }),
    [screenWidth]
  );

  const sidebarRoutes = [
    {
      title: 'My Profile',
      goto: '/my-profile',
    },
    {
      title: `Draft Articles (${counts?.draftCount || 0})`,
      goto: '/my-profile/draft',
    },
    {
      title: `Published Articles (${counts?.publishedCount || 0})`,
      goto: '/my-profile/published',
    },
    {
      title: `Followed Topics (${myTags?.length || 0})`,
      goto: '/my-profile/topics',
    },
    {
      title: `Following (${user?.followersArray.length || 0})`,
      goto: '/my-profile/following',
    },
  ];

  return (
    <div className='profile-wrapper'>
      <Header
        loggedIn={isLoggedIn}
        isArticlePage={true}
        ScreenWidth={screenWidth}
        isMyProfilePage={true}
        tokens={user?.nuaTokens}
        loading={false}
      />
      <div className='container'>
        {mobile ? (
          <div className='sidebar'>
            <MyProfileSidebar />
          </div>
        ) : (
          <div className='sidebar'>
            {sidebarRoutes.map((route) => {
              return (
                <Link
                  className={`route ${
                    location.pathname === route?.goto && 'active'
                  }`}
                  key={route?.goto}
                  to={route?.goto}
                >
                  {route?.title}
                </Link>
              );
            })}
            <div className='hr' />
            <Button
              styleType='secondary'
              type='button'
              style={{ width: '130px' }}
              onClick={() => {
                navigate('/article/new', { replace: true });
              }}
            >
              Create new article
            </Button>
          </div>
        )}

        <Outlet />
      </div>
    </div>
  );
};

export default ProfileSidebar;
