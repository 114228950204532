import React, { useEffect, useState } from 'react';
import Button from '../../UI/Button/Button';
import { useNavigate } from 'react-router';
import Footer from '../../components/footer/footer';
import LoggedOutSidebar from '../../components/logged-out-sidebar/logged-out-sidebar';
import Header from '../../components/header/header';
import { useUserStore } from '../../store';

const TimedOut = () => {
  const [screenWidth, setScreenWidth] = useState(0);
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate('/', { replace: true });
  };

  const { user, getUser } = useUserStore((state) => ({
    user: state.user,
    getUser: state.getUser,
  }));

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (user) {
      goToHomePage();
    }
  }, [user]);

  useEffect(
    (window.onresize = window.onload =
      () => {
        setScreenWidth(window.innerWidth);
      }),
    [screenWidth]
  );

  return (
    <div className='homepage-wrapper'>
      <Header
        loggedIn={false}
        isArticlePage={false}
        ScreenWidth={screenWidth}
        tokens={user?.nuaTokens}
        loading={false}
      />

      <div className='logged-out-mobile'>
        <LoggedOutSidebar responsiveElement={true} />
      </div>
      <div className='main'>
        <div className='left'>
          <div className='logged-out'>
            <LoggedOutSidebar responsiveElement={false} />
          </div>
        </div>
        <div className='right'>
          <div className='content'>
            <p className='title'>SESSION TIMED OUT</p>
            <p>Your session has timed-out. Please login again.</p>
            <Button
              styleType='primary-1'
              type='button'
              style={{ width: '96px' }}
              onClick={goToHomePage}
            >
              Home
            </Button>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimedOut;
