import React, { useEffect } from 'react';
import CardDraftArticles from '../../components/card-draft-articles/card-draft-articles';
import { usePostStore, useUserStore, useAuthStore } from '../../store';
import { PostType } from '../../types/types';
import { useNavigate } from 'react-router-dom';

const PublishedArticles = () => {
  const { user, getUser, getCounts, counts } = useUserStore((state) => ({
    user: state.user,
    getUser: state.getUser,
    getCounts: state.getUserPostCounts,
    counts: state.userPostCounts,
  }));

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const navigate = useNavigate();

  const { getMyPublishedPosts, myPublishedPosts } = usePostStore((state) => ({
    getMyPublishedPosts: state.getMyPublishedPosts,
    myPublishedPosts: state.myPublishedPosts,
  }));

  useEffect(() => {
    getUser();
    getMyPublishedPosts(0, 1000);
  }, []);

  useEffect(() => {
    if (user) {
      getCounts(user.handle);
    }
  }, [user]);

  useEffect(() => {
    if (isLoggedIn && !user) {
      navigate('/', { replace: true });
    }
  }, [isLoggedIn, user]);

  return (
    <div className='wrapper' style={{ width: '100%' }}>
      <p className='title'>
        PUBLISHED ARTICLES ({counts?.publishedCount || 0})
      </p>
      <div className='article-grid-horizontal'>
        {(myPublishedPosts || []).map((post: PostType) => (
          <CardDraftArticles post={post} key={post.postId} />
        ))}
      </div>
    </div>
  );
};

export default PublishedArticles;
