import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CardVerticalProps } from './types';
import { DateFormat, formatDate } from '../../shared/utils';
import { images } from '../../shared/constants';

const CardVertical: React.FC<CardVerticalProps> = ({ post }) => {
  const navigate = useNavigate();

  return (
    <div className='card-wrapper'>
      <img
        className='main-pic'
        src={post.headerImage || images.NUANCE_LOGO}
        alt='Article header image'
        onClick={() => {
          navigate(post.url);
        }}
      />

      <div className='publisher'>
        <div className='card-creator'>
          {post && (
            <>
              <img
                className='profile-pic'
                src={post.avatar || images.DEFAULT_AVATAR}
                alt='Author image'
              />
              <div>
                <Link to={`/${post.handle}`}>@{post.handle}</Link>
              </div>
            </>
          )}
        </div>

        <div className='published-date'>
          <div className='divider' />
          <p>{formatDate(post.created, DateFormat.NoYear)}</p>
        </div>
      </div>
      <div className='article-text'>
        <Link to={post.url}>
          <h2>{post.title}</h2>
        </Link>
        <p>{post.subtitle}</p>
      </div>
    </div>
  );
};

export default CardVertical;
